<template>
  <div
    class="MemorizeView"
    :class="{ timeup: countdown === 0, completed }">
    <ProgressBar :progress="progress" />
    <GameHeader
      class="header"
      :countdown="countdown" />

    <div class="body">
      <component
        :is="currentItemConf.headerComponent"
        v-bind="currentItemConf.headerProps" />
      <div class="progressText">
        {{ currentItemIndex + 1 }}/{{ items.length }}
      </div>
    </div>

    <div class="cardContainer">
      <transition :name="transitionName">
        <component
          :is="currentItemConf.bodyComponent"
          :key="currentItemIndex"
          class="Card"
          v-bind="currentItemConf.bodyProps" />
      </transition>
    </div>

    <div class="footer">
      <transition
        v-if="memorizeSession.timeoutPerItem"
        :name="transitionName"
        mode="out-in">
        <ButtonWithCountdown
          :key="currentItemIndex"
          :time-limit="currentItem.timeLimit"
          @click="next()" />
      </transition>
      <NavigationWithArrows
        v-else
        class="navigation"
        :length="items.length"
        :index="currentItemIndex"
        auto-disable="left"
        @click-prev="prev()"
        @click-next="next()" />
    </div>
  </div>
</template>

<script>
import soundService from '@/services/sound-service';
import gameHelper from '@/modules/games-shared/game-helper';

import timeoutMixin from '../timeoutMixin';

import GameHeader from '../components/GameHeader';
import ProgressBar from '../components/ProgressBar';
import NavigationWithArrows from '../components/NavigationWithArrows';
import Countdown from '../components/Countdown';
import ButtonWithCountdown from '../components/ButtonWithCountdown';

export default {
    components: { GameHeader, NavigationWithArrows, Countdown, ButtonWithCountdown, ProgressBar },
    inject: ['module', 'transition', 'theme'],
    mixins: [timeoutMixin],
    data() {
        return {
            countdown: this.module.state.memorize.timeoutPerItem ? -1 : this.module.state.memorize.timeLimit,
            direction: 1,
            completed: false
        };
    },
    computed: {
        transitionName() {
            return this.direction > 0 ? 'out-left' : 'out-right';
        },
        memorizeSession() {
            return this.module.state.memorize;
        },
        items() {
            return this.memorizeSession.items;
        },
        currentItemIndex() {
            return this.memorizeSession.pageIndex; // NOTE: assumes 1 item per page
        },
        currentItem() {
            return this.items[this.currentItemIndex];
        },
        currentItemConf() {
            return {
                headerComponent:
                    gameHelper.sessions[this.memorizeSession.sessionId].quizMapping[this.currentItem.memorizeItemType]
                        .instructionComponent,
                headerProps: this.currentItem.data,
                bodyComponent:
                    gameHelper.sessions[this.memorizeSession.sessionId].quizMapping[this.currentItem.memorizeItemType]
                        .component,
                bodyProps: this.currentItem.data
            };
        },
        progress() {
            return this.completed ? 1 : this.currentItemIndex / this.items.length;
        }
    },
    mounted() {
        soundService.play(this.theme.sounds.themeSound, { volume: 0.5, loop: true }, 'background');
    },
    beforeDestroy() {
        soundService.stop(this.theme.sounds.themeSound, 500);
        clearInterval(this.intervalId2);
        clearTimeout(this.completionTimeoutId);
    },
    methods: {
        prev() {
            if (this.memorizeSession.timeoutPerItem) {
                return;
            }
            this.direction = -1;
            this.transition('PREV');
        },
        next() {
            this.direction = 1;
            if (this.currentItemIndex === this.items.length - 1) {
                this.completed = true;
                this.completionTimeoutId = setTimeout(() => {
                    this.transition('START_QUIZ');
                }, 1000);
                return;
            }
            this.transition('NEXT');
        },
        onTimeout() {
            soundService.stop(this.theme.sounds.themeSound, 500);
            soundService.play(this.theme.sounds.timeupSound, { volume: 2 });
            setTimeout(() => {
                this.transition('START_QUIZ');
            }, 2000);
        }
    }
};
</script>

<style scoped lang="scss">
$transitionDuration: 0.5s;

.MemorizeView {
    display: flex;
    flex-direction: column;

    transition: opacity 1s;

    &.timeup {
        opacity: 0;
    }
    &.completed {
        opacity: 0;
        .Card {
            transition: transform $transitionDuration;
            transform: translate3d(-100%, 0, 0);
        }
    }
}

.body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.progressText {
    margin-top: 0.5em;
    color: rgba(white, 0.5);
}

.cardContainer {
    position: relative;
    flex: 1;
}

.Card {
    flex: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &.out-left-enter-active,
    &.out-left-leave-active,
    &.out-right-enter-active,
    &.out-right-leave-active {
        transition: opacity $transitionDuration, transform $transitionDuration;
    }

    &.out-left-enter {
        transform: translate3d(100%, 0, 0);
    }
    &.out-right-enter {
        transform: translate3d(-100%, 0, 0);
    }
    &.out-left-leave-to {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
    }
    &.out-right-leave-to {
        opacity: 0;
        transform: translate3d(100%, 0, 0);
    }
}

.ButtonWithCountdown {
    &.out-left-enter-active {
        transition: opacity $transitionDuration, transform $transitionDuration;
    }
    &.out-left-leave-active {
        transition: opacity $transitionDuration, transform $transitionDuration;
    }

    &.out-left-enter {
        transform: translate3d(1em, 0, 0);
        // transform: translate3d(0, 100%, 0);
        opacity: 0;
    }
    &.out-left-leave-to {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
        // transform: translate3d(0, 100%, 0);
    }
}

.spacer {
    flex: 1;
}
.spacerTop {
    flex: 0.7;
}

.spacer {
    flex: 1;
}

.footer {
    padding: 1em 0em;
    padding-bottom: 2em;
    display: flex;
    flex-direction: column;
}
.Countdown {
    align-self: center;
    margin-bottom: 1em;
}
</style>
