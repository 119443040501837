<template>
  <div
    class="ButtonWithCountdown"
    :class="{ full: progress >= 1 }">
    <div
      class="circle --global-clickable"
      @click="$emit('click')">
      <svgicon
        class="arrowIcon"
        icon="arrow-right" />

      <div class="circleAnim1" />
      <CircularProgress
        :progress="progress"
        color="rgba(255,255,255,0.8)"
        track-color="rgba(255,255,255,0.1)" />
    </div>
    <!-- <div class="time">
      {{ remaining | timestamp }}
    </div> -->
  </div>
</template>

<script>
import CircularProgress from '@/components/CircularProgress';

function pad(num) {
    return ('0' + num).slice(-2);
}
function hhmmss(secs) {
    let minutes = Math.floor(secs / 60);
    secs = secs % 60;
    let hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    return (hours ? pad(hours) + ':' : '') + (minutes ? pad(minutes) + ':' : '') + pad(secs);
}
function timestampFilter(seconds) {
    return hhmmss(seconds);
}

export default {
    components: { CircularProgress },
    filters: {
        timestamp: timestampFilter
    },
    props: {
        timeLimit: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            timeUsed: 0,
            adjust: 0
        };
    },
    computed: {
        remaining() {
            return this.timeLimit - this.timeUsed;
        },
        progress() {
            if (!this.timeLimit) {
                return 0;
            }
            return Math.min(1, (this.timeUsed + this.adjust) / this.timeLimit);
        }
    },
    mounted() {
        if (this.timeLimit) {
            setTimeout(() => {
                this.adjust = 1;
                this.start();
            }, 100);
        }
    },
    beforeDestroy() {
        clearInterval(this.intervalId2);
    },
    methods: {
        start() {
            clearInterval(this.intervalId2);
            this.intervalId2 = setInterval(() => {
                if (this.timeUsed < this.timeLimit) {
                    this.timeUsed += 1;
                } else {
                    clearInterval(this.intervalId2);
                    this.$emit('click');
                }
            }, 1000);
        }
    }
};
</script>

<style scoped lang="scss">
.ButtonWithCountdown {
    &.full {
        .circleAnim1 {
            transition: opacity 1s;
            opacity: 0;
        }
    }
}
.time {
    text-align: center;
    color: rgba(white, 0.5);
    margin-top: 1em;
}
.circle {
    $size: 3em;
    position: relative;
    width: $size;
    height: $size;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    margin: auto;
    margin-top: 0.5em;
}

.circleAnim1 {
    position: absolute;
    $offset: -5px;
    top: $offset;
    right: $offset;
    bottom: $offset;
    left: $offset;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: rgba(black, 0.1);
    animation: spin 2s linear infinite;
}

.arrowIcon {
    $size: 1.5em;
    width: $size;
    height: $size;
    fill: rgba(white, 0.8);
}

.CircularProgress {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
