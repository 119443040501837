import Vue from 'vue';
import last from 'lodash-es/last';
import sortBy from 'lodash-es/sortBy';
import gameHelper from '@/modules/games-shared/game-helper';

export default {
    namespaced: true,
    state() {
        return {
            sessionId: null,
            sourceItems: [],
            shelfItems: [],
            targetItems: [],
            items: [],
            itemIndex: -1,

            lives: 3,
            lastIdleEnterTime: 0,
            done: false,
            score: 0,

            sequentialMode: false,
            loading: false
        };
    },
    actions: {
        async enter({ state, commit }) {
            commit('setLoading', true);
            const data = await gameHelper.sessions[state.sessionId].generateQuiz();
            commit('update', data);
            commit('setLoading', false);
        },
        leave({ state, commit }) {
            const timeUsed = state.items
                .map(item => item.timeUsed)
                .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            gameHelper.sessions[state.sessionId].reportQuiz({
                items: state.items,
                timeUsed,
                score: state.score,
                sourceItems: sortBy(state.targetItems, ['data.number'])
            });
        },
        enterIdle({ commit }) {
            commit('update', { lastIdleEnterTime: Date.now() });
        },
        enterItem({ dispatch, commit, state }, { targetItemNumber, droppedItemNumber }) {
            const sourceItem = state.targetItems.find(item => item.data.number === targetItemNumber);
            const typ = sourceItem.quizItemType;

            const existingItem = state.items.find(item => item.sourceItemNumber === targetItemNumber);
            if (existingItem) {
                commit('setItemIndex', existingItem.index);
                commit('updateItem', {
                    itemIndex: existingItem.index,
                    data: {
                        droppedItemNumber,
                        timeUsed: existingItem.timeUsed + (Date.now() - state.lastIdleEnterTime)
                    }
                });
            } else {
                const testNames = gameHelper.sessions[state.sessionId].quizMapping[typ].generateTestNames(
                    sourceItem.data
                );
                const index = state.items.length;
                const quizItemSession = {
                    sourceItemNumber: targetItemNumber,
                    sourceItemIndex: targetItemNumber - 1,
                    droppedItemNumber,
                    index,
                    timeUsed: Date.now() - state.lastIdleEnterTime,
                    score: 0,
                    done: false,
                    tests: [],
                    testNames,
                    type: typ
                };
                commit('setItemIndex', index);
                commit('addItem', quizItemSession);
            }
        },
        leaveItem({ commit, getters, state }) {
            const item = getters.currentItem;
            let timeUsed = item.timeUsed;
            let allCorrect = true;
            item.tests.forEach(test => {
                timeUsed += test.end - test.start;
                if (test.score < 1) {
                    allCorrect = false;
                }
            });
            commit('updateItem', {
                itemIndex: item.index,
                data: {
                    timeUsed,
                    done: true,
                    score: allCorrect ? 1 : 0
                }
            });
        },

        enterTest({ state, getters, commit, dispatch }) {
            const item = getters.currentItem;
            const previousTest = last(item.tests);
            const sourceItem = state.sourceItems[item.sourceItemNumber - 1];

            const previousTestName = previousTest ? previousTest.name : '';
            const currentTestIndex = item.testNames.indexOf(previousTestName);

            // NOTE: only proceed to next test if previous answered correctly
            const testName =
                previousTest && previousTest.score < 1 ? previousTestName : item.testNames[currentTestIndex + 1];
            const data = gameHelper.sessions[state.sessionId].quizMapping[item.type].tests[testName].generateTestData(
                sourceItem
            );

            const test = {
                start: Date.now(),
                end: 0,
                score: 0,
                done: false,
                submittedAnswer: null,
                prepopulatedAnswer: item.droppedItemNumber,
                name: testName,
                data
            };

            commit('addTest', { itemIndex: item.index, test });
        },
        leaveTest({ commit, state }) {
            commit('updateTest', {
                itemIndex: state.itemIndex,
                data: {
                    end: Date.now(),
                    done: true
                }
            });
        },
        submitTestAnswer({ commit, getters, dispatch, state }, answer) {
            const quizItem = getters.currentItem;
            const quizItemTest = last(quizItem.tests);
            const sourceItem = state.sourceItems[quizItem.sourceItemNumber - 1];
            const score = gameHelper.sessions[state.sessionId].quizMapping[quizItem.type].tests[
                quizItemTest.name
            ].calculateScore(sourceItem, answer.value, quizItemTest);

            commit('updateTest', {
                itemIndex: state.itemIndex,
                data: {
                    score: score,
                    submittedAnswer: answer.value
                }
            });

            if (quizItemTest.name === 'matchTest' && score === 1) {
                commit('addMatchAttempt', { number: quizItem.sourceItemNumber, score });
            }

            if (score < 1) {
                commit('update', { lives: state.lives - 1 });
            }
        }
    },
    mutations: {
        setSessionId(state, sessionId) {
            state.sessionId = sessionId;
        },

        setLoading(state, flag) {
            state.loading = flag;
        },

        update(state, data) {
            Object.assign(state, data);
        },

        setItemIndex(state, itemIndex) {
            state.itemIndex = itemIndex;
        },

        updateShelfItem(state, { number, data }) {
            let x = state.shelfItems.find(item => item.number === number);
            Object.assign(x, data);
        },
        addItem(state, quizItem) {
            state.items.push(quizItem);
        },
        addTest(state, { itemIndex, test }) {
            state.items[itemIndex].tests.push(test);
        },
        updateTest(state, { itemIndex, data }) {
            const test = last(state.items[itemIndex].tests);
            Object.assign(test, data);
        },
        updateItem(state, { itemIndex, data }) {
            Object.assign(state.items[itemIndex], data);
        },

        addMatchAttempt(state, { number, score }) {
            Vue.set(
                state.targetItems.find(item => item.data.number === number),
                'match',
                score === 1
            );
            Vue.set(
                state.shelfItems.find(item => item.number === number),
                'match',
                score === 1
            );
        }
    },

    getters: {
        currentItem(state) {
            return state.items[state.itemIndex] || null;
        },
        currentTest(state, getters) {
            return getters.currentItem ? last(getters.currentItem.tests) : null;
        },
        getItem: (state, getters) => itemIndex => {
            return state.items[itemIndex] || null;
        }
    }
};
