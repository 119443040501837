<template>
  <div class="Countdown">
    {{ value | timestamp }}
  </div>
</template>

<script>
function pad(num) {
    return ('0' + num).slice(-2);
}
function hhmmss(secs) {
    let minutes = Math.floor(secs / 60);
    secs = secs % 60;
    let hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    return (hours ? pad(hours) + ':' : '') + (minutes ? pad(minutes) + ':' : '') + pad(secs);
}
function timestampFilter(seconds) {
    return hhmmss(seconds);
}

export default {
    filters: {
        timestamp: timestampFilter
    },
    props: ['value']
};
</script>

<style scoped lang="scss">
.Countdown {
    color: rgba(white, 0.7);
    // $size: 3em;
    // width: $size;
    // height: $size;
    // border-radius: 50%;
    // border: 1px solid rgba(white, 0.1);
    // display: flex;
    // justify-content: center;
    // align-items: center;
}
</style>
