<template>
  <div class="SoundSettingsPopup">
    <div class="Field">
      <div class="icon">
        <svgicon
          class="svgIcon"
          :icon="volume ? 'music' : 'music-mute'" />
      </div>
      <div class="label">
        Music
      </div>
      <Slider v-model="volume" />
    </div>
    <div class="divider" />
    <div class="Field">
      <div class="icon">
        <svgicon
          class="svgIcon"
          :icon="soundIcon" />
      </div>
      <div class="label">
        Sound
      </div>
      <Slider v-model="effectVolume" />
    </div>
  </div>
</template>

<script>
import Slider from './Slider';

export default {
    components: { Slider },
    computed: {
        soundIcon() {
            if (this.effectVolume > 0.7) {
                return 'sound-high';
            } else if (this.effectVolume > 0.4) {
                return 'sound-medium';
            } else if (this.effectVolume > 0) {
                return 'sound-low';
            } else {
                return 'sound-mute';
            }
        },
        volume: {
            get: function () {
                return this.$store.state.moduleMemogenius.settings.backgroundSoundVolume;
            },
            set: function (volume) {
                this.$store.dispatch('moduleMemogenius/setBackgroundSoundVolume', volume);
            }
        },
        effectVolume: {
            get: function () {
                return this.$store.state.moduleMemogenius.settings.effectSoundVolume;
            },
            set: function (volume) {
                this.$store.dispatch('moduleMemogenius/setEffectSoundVolume', volume);
            }
        }
    }
};
</script>

<style scoped lang="scss">
.SoundSettingsPopup {
    background-color: white;
    padding: 2em;
    color: black;
}
.volume {
    &.active {
        border-bottom: 1px solid black;
    }
}
.Field {
    display: flex;
    align-items: center;
}
.icon {
    $size: 1em;
    width: $size;
    height: $size;
    margin-right: 1em;
}
.svgIcon {
    fill: black;
    $size: 1em;
    width: $size;
    height: $size;
}
.Slider {
    width: 10em;
    margin-left: 2em;
}
.label {
    flex: 1;
}
.divider {
    height: 1px;
    background-color: rgba(black, 0.05);
    margin: 2em 0;
}
</style>
