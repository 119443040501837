<template>
  <div class="Lives">
    <svgicon
      v-for="life of 3"
      :key="life"
      class="Life"
      :class="{ isFull: life <= remaining }"
      icon="heart-like" />
  </div>
</template>

<script>
export default {
    props: {
        remaining: {
            type: Number,
            required: true
        }
    }
};
</script>

<style scoped lang="scss">
.Lives {
    display: flex;
    align-items: center;
}
.Life {
    $size: 1em;
    width: $size;
    height: $size;
    fill: rgba(white, 0.2);

    &:not(:first-child) {
        margin-left: 0.5em;
    }

    &.isFull {
        fill: red;
    }
}
</style>
