import store from '@/store';

export default function wrapTransition(moduleName, sessionId, machine) {
    sessionId = 's';
    return function transition(event, payload = {}) {
        payload.type = event;
        event = payload;
        const nextState = machine.transition(
            store.state[moduleName][sessionId].machineState,
            event,
            store.state[moduleName]
        );

        nextState.actions.forEach(actionKey => {
            store.dispatch(`${moduleName}/${sessionId}/${actionKey}`, event);
        });

        store.commit(`${moduleName}/${sessionId}/setMachineState`, nextState.value);
    };
}
