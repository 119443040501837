import LoadingView from './views/LoadingView';
import CountdownView from './views/CountdownView';
import ExitView from './views/ExitView';
import EmptyView from './views/EmptyView';

export default [
    {
        path: '/initial',
        component: EmptyView
    },
    {
        path: '/session/memorize/loading',
        component: LoadingView,
        props: {
            phase: 'memorize'
        }
    },
    {
        path: '/session/memorize/countdown',
        component: CountdownView
    },
    {
        path: '/session/quiz/loading',
        component: LoadingView,
        props: {
            phase: 'quiz'
        }
    },
    {
        path: '/session/quiz/countdown',
        component: CountdownView
    },
    {
        path: '/session/success',
        component: ExitView,
        props: {
            signal: 'success'
        }
    },
    {
        path: '/session/fail',
        component: ExitView,
        props: {
            signal: 'fail'
        }
    },
    {
        path: '/exit',
        name: 'exit',
        props: {
            signal: 'exit'
        },
        component: ExitView
    }
];
