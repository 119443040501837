export default {
    data() {
        return {
            countdown: 0
        };
    },
    methods: {
        onTimeout() {},
        timeoutMixin_start() {
            this.timeoutMixin_intervalId = setInterval(() => {
                this.countdown -= 1;
                if (this.countdown === 0) {
                    clearInterval(this.timeoutMixin_intervalId);
                    this.onTimeout();
                }
            }, 1000);
        },
        timeoutMixin_pause() {
            clearInterval(this.timeoutMixin_intervalId);
        },
        timeoutMixin_resume() {
            clearInterval(this.timeoutMixin_intervalId);
            this.start();
        }
    },
    mounted() {
        if (!this.countdown) {
            this.countdown = -1;
            return;
        }
        this.timeoutMixin_start();
    },
    beforeDestroy() {
        clearInterval(this.timeoutMixin_intervalId);
    }
};
