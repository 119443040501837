<template>
  <div class="Slider">
    <div class="track">
      <div
        class="progress"
        :style="{ width: `${value * 100}%` }" />
    </div>
    <div
      class="knob"
      :style="{ left: `calc(${value * 100}% - 0.8em)` }" />
  </div>
</template>

<script>
import Hammer from 'hammerjs';

export default {
    props: {
        value: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            width: 0,
            x: 0
        };
    },
    mounted() {
        this.hammer = new Hammer.Manager(this.$el, {
            recognizers: [
                [Hammer.Pan, { direction: Hammer.DIRECTION_HORIZONTAL, threshold: 0 }],
                [Hammer.Press, { time: 0 }]
            ]
        });
        this.hammer.on('panmove', this.handlePanMove);
        this.hammer.on('press', this.handlePress);
    },
    beforeDestroy() {
        this.hammer.destroy();
    },
    methods: {
        handlePanMove(e) {
            this.process(e.center.x);
        },
        handlePress(e) {
            const { width, x } = this.$el.getBoundingClientRect();
            this.width = width;
            this.x = x;
            if (e.target.classList.contains('knob')) {
            }
            this.process(e.center.x);
        },
        process(absoluteX) {
            let localX = absoluteX - this.x;
            localX = Math.min(Math.max(0, localX), this.width);
            this.$emit('input', localX / this.width);
        }
    }
};
</script>

<style scoped lang="scss">
$trackHeight: 1em;
$knobHeight: 1.6em;

.Slider {
    position: relative;
    padding: #{($knobHeight - $trackHeight) / 2} 0;
}

.track {
    background-color: rgba(black, 0.05);
    border-radius: 2em;
    overflow: hidden;
}
.progress {
    height: $trackHeight;
    background-color: #7ad84d;
}
.knob {
    width: $knobHeight;
    height: $knobHeight;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 0 0.2em #7ad84d;
    position: absolute;
    top: 0;
}
</style>
