<template>
  <div
    ref="root"
    class="QuizView">
    <ProgressBar :progress="progress" />
    <GameHeader
      class="header"
      :countdown="countdown"
      :show-lives="true" />

    <div
      class="body"
      :class="{ showingVisibleQuizItemLayer }">
      <SourceTargetInteraction
        :target-ids="targetIds"
        :source-ids="sourceIds"
        :check-match="checkMatch"
        source-selector=".ShelfItem"
        :target-selector="sequentialMode ? '.TargetItemLarge' : '.TargetGrid-Item'">
        <template slot-scope="{ sourceItems, targetItems }">
          <template v-if="sequentialMode">
            <div class="instruction">
              {{ $t('instruction') }} {{ targetItemsMap[currentTargetId].data.number }}?
            </div>
            <div class="instruction2">
              {{ $t('instruction2') }}
            </div>
            <div class="targetItemLargeContainer">
              <transition name="out-left">
                <div
                  :key="currentTargetId"
                  class="targetItemLargeSlide">
                  <TargetItemLarge
                    class="TargetItemLarge"
                    v-bind="targetItemsMap[currentTargetId].data"
                    :show-numbershape-hint="targetItemsMap[currentTargetId].showNumbershapeHint"
                    :numbering-style="targetItemsMap[currentTargetId].numberingStyle"
                    :hover="targetItems[currentTargetItemIndex].hover"
                    :matched="targetItems[currentTargetItemIndex].matched"
                    :data-id="currentTargetId" />
                </div>
              </transition>
            </div>
            <div class="spacer" />
          </template>
          <template v-else>
            <div class="instruction">
              {{ $t('currentGame.QuizView.instruction') }}
            </div>

            <div class="grid">
              <TargetItem
                v-for="targetItem of targetItems"
                :key="targetItem.id"
                class="TargetGrid-Item"
                v-bind="targetItem"
                :data="targetItemsMap[targetItem.id]"
                :data-id="targetItem.id" />
            </div>

            <br>
          </template>

          <Shelf
            class="shelf"
            :items="sourceItems"
            :background="theme.shelfBackground">
            <template
              slot="shelfItem"
              slot-scope="sourceItem">
              <ShelfItemWithImage
                class="ShelfItem"
                :state="sourceItem"
                v-bind="sourceItemsMap[sourceItem.id]"
                :data-id="sourceItem.id" />
            </template>
          </Shelf>
        </template>

        <template
          slot="dragItem"
          slot-scope="props">
          <div
            class="dragItem"
            :style="{ backgroundImage: `url(${sourceItemsMap[props.id].image})` }" />
        </template>
      </SourceTargetInteraction>

      <component
        :is="currentQuizItemComponent"
        v-if="showingQuizItemLayer"
        class="QuizItem"
        :item-index="currentQuizItem.index"
        @request-next="onLeaveQuizItemSession" />
    </div>
  </div>
</template>

<translations>
  instruction: What is number
  instruction_no: Hva er nummer
  instruction2: Drag and drop the correct item
  instruction2_no: Dra og slipp riktig bilde
</translations>

<script>
import soundService from '@/services/sound-service';

import SourceTargetInteraction from '@/modules/games-shared/components/SourceTargetInteraction';
import Shelf from '@/modules/games-shared/components/Shelf';
import ShelfItemWithImage from '@/modules/games-shared/components/ShelfItemWithImage';

import GameHeader from '@/modules/games-shared/components/GameHeader';
import timeoutMixin from '@/modules/games-shared/timeoutMixin';
import ProgressBar from '@/modules/games-shared/components/ProgressBar';

import gameHelper from '@/modules/games-shared/game-helper';

import TargetItem from '../components/TargetItem';
import TargetItemLarge from '../components/TargetItemLarge';

export default {
    components: {
        GameHeader,
        TargetItem,
        TargetItemLarge,
        ProgressBar,
        // ShelfItem,
        ShelfItemWithImage,
        SourceTargetInteraction,
        Shelf
    },
    inject: ['module', 'transition', 'theme'],
    mixins: [timeoutMixin],
    data() {
        return {
            countdown: this.module.state.quiz.timeLimit,
            showingQuizItemLayer: false,
            currentTargetItemIndex: 0
        };
    },
    computed: {
        quizSession() {
            return this.module.state.quiz;
        },
        sourceIds() {
            return this.quizSession.shelfItems.map(item => {
                return `${item.id}`;
            });
        },
        targetIds() {
            return this.quizSession.targetItems.map(item => {
                return `${item.data.number}`;
            });
        },

        sourceItemsMap() {
            const m = {};
            this.quizSession.shelfItems.forEach((item, i) => {
                m[`${item.id}`] = item;
                // console.log('shelfItem', item)
            });
            return m;
        },
        targetItemsMap() {
            const m = {};
            this.quizSession.targetItems.forEach((item, i) => {
                m[`${item.data.number}`] = item;
                // console.log('targetItem', item)
            });
            return m;
        },
        sequentialMode() {
            return this.quizSession.sequentialMode;
        },
        currentTargetId() {
            return this.targetIds[this.currentTargetItemIndex];
        },
        currentQuizItem() {
            return this.module.getters('quiz/currentItem');
        },
        currentQuizItemComponent() {
            if (!this.showingQuizItemLayer) {
                return null;
            }
            return gameHelper.sessions[this.quizSession.sessionId].quizMapping[this.currentQuizItem.type].component;
        },
        showingVisibleQuizItemLayer() {
            if (!this.showingQuizItemLayer) {
                return false;
            }
            const currentTest = this.module.getters('quiz/currentTest');
            return (
                currentTest &&
                !gameHelper.sessions[this.quizSession.sessionId].quizMapping[this.currentQuizItem.type].tests[
                    currentTest.name
                ].isInvisible
            );
        },
        progress() {
            const numberOfItems = this.quizSession.targetItems.length;
            if (this.sequentialMode) {
                return this.quizSession.items.filter(item => item.done).length / numberOfItems;
            }
            const numberOfMatches = this.quizSession.targetItems.filter(item => item.match).length;
            return numberOfMatches / numberOfItems;
        }
    },
    mounted() {
        soundService.play(this.theme.sounds.themeSound, { volume: 0.5, loop: true }, 'background');
    },
    beforeDestroy() {
        soundService.stop(this.theme.sounds.themeSound, 500);
    },
    methods: {
        onLeaveQuizItemSession() {
            this.showingQuizItemLayer = false;
            this.transition('LEAVE_QUIZ_ITEM');

            if (this.quizSession.lives === 0) {
                this.transition('LEVEL_FAIL');
            } else if (this.progress === 1) {
                this.transition('LEVEL_SUCCEED');
            } else if (this.sequentialMode) {
                this.currentTargetItemIndex += 1;
            }
        },
        onTimeout() {
            this.transition('LEVEL_FAIL');
        },
        checkMatch(sourceId, targetId) {
            sourceId = parseInt(sourceId, 10);
            targetId = parseInt(targetId, 10);
            this.transition('ENTER_QUIZ_ITEM', { targetItemNumber: targetId, droppedItemNumber: sourceId });
            this.showingQuizItemLayer = [targetId, sourceId];
            return sourceId === targetId;
        }
    }
};
</script>

<style scoped lang="scss">
.QuizView {
    display: flex;
    flex-direction: column;
}

.body {
    position: relative;
    flex: 1;
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.showingVisibleQuizItemLayer {
        & > *:not(.QuizItem) {
            opacity: 0;
        }
        .QuizItem {
            opacity: 1;
        }
    }
}

.instruction {
    font-size: 140%;
    text-align: center;
}

.SourceTargetInteraction {
    flex: 1;
    align-self: stretch;
}

.grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start; // NOTE: needed when this box has flex: 1

    margin-top: 2em;

    flex: 1;
    overflow-y: auto;
}

.TargetGrid-Item,
.TargetItem {
    margin: 0.5em;
}

.shelf {
    align-self: stretch;
    margin-top: 1em;
}

.QuizItem {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    opacity: 0;
}

.instruction2 {
    text-align: center;
    color: rgba(white, 0.5);
    margin-top: 1em;
}

$targetItemLargeSize: 12em;
.targetItemLargeContainer {
    position: relative;
    width: $targetItemLargeSize;
    height: $targetItemLargeSize;
    margin: auto;
    margin-top: 4em;
}
.targetItemLargeSlide {
    $transitionDuration: 0.5s;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &.out-left-enter-active,
    &.out-left-leave-active {
        transition: opacity $transitionDuration, transform $transitionDuration;
    }
    &.out-left-enter {
        opacity: 0;
        transform: translate3d(110%, 0%, 0);
    }
    &.out-left-leave-to {
        opacity: 0;
        transform: translate3d(-110%, 0%, 0);
    }
}
.TargetItemLarge {
    width: $targetItemLargeSize;
    height: $targetItemLargeSize;
}
.spacer {
    flex: 1;
}

.dragItem {
    $size: 5em;
    width: $size;
    height: $size;
    padding: 1em;
    opacity: 0.5;
    border-radius: 0.5em;

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
</style>
