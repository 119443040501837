function calcNumberOfFakeItems(itemCount) {
    return Math.floor(itemCount * 0.2);
}

function generateLevel({ numberOfItems, includeQuantity = false, includePrice = false }) {
    const complexity = 1 + (includeQuantity ? 1 : 0) + (includePrice ? 1 : 0);
    const itemTime = 10 * complexity;
    return {
        numberOfItems,
        numberOfFakeItems: calcNumberOfFakeItems(numberOfItems),
        includeQuantity,
        includePrice,
        itemMemorizeTimeLimit: itemTime,
        itemQuizTimeLimit: itemTime,
        stars2: itemTime * 0.7,
        stars3: itemTime * 0.5
    };
}

const rawLevelOptions = [
    { numberOfItems: 10 }, // 1.1
    { numberOfItems: 15 }, // 1.2

    { numberOfItems: 20 }, // 2.1
    { numberOfItems: 25 }, // 2.2
    { numberOfItems: 30 }, // 2.3

    { numberOfItems: 20, includeQuantity: true }, // 3.1
    { numberOfItems: 25, includeQuantity: true }, // 3.2
    { numberOfItems: 30, includeQuantity: true }, // 3.3

    { numberOfItems: 25, includeQuantity: true, includePrice: true }, // 4.1
    { numberOfItems: 30, includeQuantity: true, includePrice: true }, // 4.2
    { numberOfItems: 35, includeQuantity: true, includePrice: true }, // 4.3
    { numberOfItems: 40, includeQuantity: true, includePrice: true } // 4.4
];

export default function getLevelOptions(levelIndex) {
    return generateLevel(rawLevelOptions[levelIndex]);
}
