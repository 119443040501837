<template>
  <div class="ExitView" />
</template>

<script>
export default {
    props: {
        signal: {
            type: String,
            required: true
        }
    },
    mounted() {
        this.$emit('game-session-done', this.signal);
    }
};
</script>

<style scoped lang="scss"></style>
