<template>
  <div class="Carousel">
    <div
      ref="left"
      class="leftSide"
      :style="{ minWidth: sidePadding }">
      <div
        v-if="showLeftPlaceholder"
        class="leftSidePlaceholder"
        @click="prev()" />
    </div>
    <div
      ref="slideContainer"
      class="slideContainer">
      <transition
        :name="transitionName"
        :css="false"
        @before-enter="beforeEnter"
        @before-leave="beforeLeave"
        @enter="enter"
        @leave="leave">
        <div
          v-if="currentItem"
          :key="internalIndex"
          class="slide">
          <slot
            name="item"
            v-bind="currentItem" />
        </div>
      </transition>
    </div>
    <div
      ref="right"
      class="rightSide"
      :style="{ minWidth: sidePadding }">
      <div
        v-if="showRightPlaceholder"
        class="rightSidePlaceholder"
        @click="next()" />
    </div>
  </div>
</template>

<script>
import Hammer from 'hammerjs';
import { TimelineLite } from 'gsap';

const hack = {
    activeCarousel: null,
    disabledCarousels: new Set()
};
let uniqueNumber = 1;

export default {
    props: {
        index: {
            type: Number,
            reqiured: true
        },
        items: {
            type: Array
        },
        transitionDuration: {
            type: Number,
            default: 1
        },
        showPlaceholders: {
            type: Boolean,
            default: false
        },
        sidePadding: {
            type: String,
            default: '2em'
        },
        horizontalOnly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            direction: 0,
            isAnimating: false,
            internalIndex: this.index,
            manual: false
        };
    },
    computed: {
        transitionName() {
            return this.direction > 0 ? 'out-left' : 'out-right';
        },
        currentItem() {
            return this.items[this.internalIndex];
        },
        showLeftPlaceholder() {
            if (this.internalIndex === 0 || (this.internalIndex === 1 && this.direction === 1)) {
                return false;
            }
            return this.showPlaceholders;
        },
        showRightPlaceholder() {
            if (
                this.internalIndex === this.items.length - 1 ||
                (this.internalIndex === this.items.length - 2 && this.direction === -1)
            ) {
                return false;
            }
            return this.showPlaceholders;
        }
    },
    watch: {
        index(newIndex, oldIndex) {
            this.manual = false;
            this.gotoCard(newIndex);
        }
    },
    mounted() {
        this.X = uniqueNumber++;
        this.hammer = new Hammer.Manager(this.$el, {
            recognizers: [
                [
                    Hammer.Pan,
                    {
                        direction: this.horizontalOnly ? Hammer.DIRECTION_HORIZONTAL : Hammer.DIRECTION_ALL,
                        threshold: 20
                    }
                ],
                [Hammer.Press, { time: 0, threshold: 1000 }, ['pan']]
            ]
        });
        this.hammer.on('panstart', this.handlePanStart);
        this.hammer.on('panmove', this.handlePanMove);
        this.hammer.on('panend', this.handlePanEnd);
        this.hammer.on('pancancel', this.handlePanCancel);
        this.hammer.on('press', this.handlePress);
        this.hammer.on('pressup', this.handlePressUp);
    },
    beforeDestroy() {
        this.hammer.destroy();
    },
    methods: {
        prev() {
            this.$emit('request-index', this.index - 1);
        },
        next() {
            this.$emit('request-index', this.index + 1);
        },
        gotoCard(index, manual = false) {
            if (this.internalIndex < index && index <= this.items.length - 1) {
                this.direction = 1;
            } else if (this.internalIndex > index && index >= 0) {
                this.direction = -1;
            } else {
                this.direction = 0;
            }
            if (this.direction) {
                this.manual = manual;
                if (this.isAnimating) {
                    this.tl.time(0);
                    this.tl.kill();
                    this.isAnimating = false;
                }
                this.internalIndex = index;
            }
        },
        beforeEnter: function (el) {
            this.elementEntering = el;
        },
        beforeLeave: function (el) {
            this.elementLeaving = el;
        },
        enter: function (el, done) {
            this.enterDone = done;
            this.setupAnimation();
        },
        leave: function (el, done) {
            this.leaveDone = done;
        },

        setupAnimation() {
            const w = this.elementEntering.clientWidth;
            const gapW = this.showPlaceholders ? 16 : 32;

            let tl = new TimelineLite({
                onComplete: () => {
                    if (this.manual) {
                        return;
                    }
                    this.isAnimating = false;
                    this.direction = 0;

                    if (this.$refs.left) {
                        this.$refs.left.style.transform = '';
                    }
                    if (this.$refs.right) {
                        this.$refs.right.style.transform = '';
                    }
                    this.enterDone();
                    this.leaveDone();

                    this.$emit('request-index', this.internalIndex);
                }
            });

            tl.fromTo(
                this.elementEntering,
                this.transitionDuration,
                {
                    opacity: this.showPlaceholder ? 0.2 : 1,
                    xPercent: (100 + (gapW * 100) / w) * this.direction,
                    scale: 1
                },
                {
                    opacity: 1,
                    xPercent: 0,
                    scale: 1
                },
                0
            );

            tl.fromTo(
                this.elementLeaving,
                this.transitionDuration,
                {
                    xPercent: 0,
                    scale: 1
                },
                {
                    opacity: this.showPlaceholder ? 0.2 : 1,
                    xPercent: -(100 + (gapW * 100) / w) * this.direction,
                    scale: 1
                },
                0
            );

            if (this.direction === -1) {
                tl.to(
                    this.$refs.right,
                    this.transitionDuration,
                    {
                        x: w + gapW
                    },
                    0
                );

                tl.fromTo(
                    this.$refs.left,
                    this.transitionDuration,
                    {
                        x: -(w + gapW)
                    },
                    {
                        x: 0
                    },
                    0
                );
            } else if (this.direction === 1) {
                tl.to(
                    this.$refs.left,
                    this.transitionDuration,
                    {
                        x: -(w + gapW)
                    },
                    0
                );

                tl.fromTo(
                    this.$refs.right,
                    this.transitionDuration,
                    {
                        x: w + gapW
                    },
                    {
                        x: 0
                    },
                    0
                );
            }

            this.tl = tl;
            this.isAnimating = true;
            if (this.manual) {
                tl.pause();
                tl.seek(0);
            }
        },

        applyHack() {
            if (hack.disabledCarousels.has(this)) {
                // Strange
            } else {
                if (!hack.activeCarousel) {
                    hack.activeCarousel = this;
                }
                hack.disabledCarousels.add(this);
                // console.log('ADD', this.X)
            }
        },
        removeHack() {
            // console.log('REMOVE', this.X, hack.disabledCarousels.size)
            hack.disabledCarousels.delete(this);

            if (hack.disabledCarousels.size === 0) {
                hack.activeCarousel = null;
            }

            // Cleanup in strange case (only observed on iOS. Caused by pressUp being called BEFOREE press !!)
            setTimeout(() => {
                if (hack.disabledCarousels.size) {
                    // console.log('STRANGE', [...hack.disabledCarousels], this.X)
                    hack.disabledCarousels.clear();
                }
                if (hack.activeCarousel) {
                    // console.log('STRANGE 2', hack.activeCarousel.X, this.X)
                    hack.activeCarousel = null;
                }
            }, 0);
        },

        isDisabled() {
            return Hammer.disableOthersHack || hack.activeCarousel !== this;
        },

        handlePanStart(event) {
            if (this.isDisabled()) {
                return;
            }
            // console.log('PAN-START', this.X)

            setTimeout(() => {
                // NOTE: panStart is not triggered in the normal bubling order or the dom-elements. A carousel that should be disabled because of Hammer.disableOthersHack, might git get to handlePanStart BEFORE Hammer.disableOthersHack is set to true. A timeout solves the problem
                if (!this.isDisabled()) {
                    this.deltaXOffset = event.deltaX;
                    if (event.direction === Hammer.DIRECTION_LEFT) {
                        this.gotoCard(this.internalIndex + 1, true);
                    } else if (event.direction === Hammer.DIRECTION_RIGHT) {
                        this.gotoCard(this.internalIndex - 1, true);
                    }
                } else {
                    // NOTE: Edge-case (observed on iOS). Probably caused by pressUp being called BEFORE this timeout. Can happen for very fast swipe?
                    // console.log('SUDDENLY DISABLED', this.X)
                }
            }, 0);
        },
        handlePanMove(event) {
            if (this.isDisabled() || !this.manual) {
                return;
            }
            const w = this.$refs.slideContainer.clientWidth + (this.showPlaceholders ? 16 : 32);

            let fraction = 0;
            let delta = event.deltaX - this.deltaXOffset;
            if (this.direction === 1) {
                fraction = Math.min(Math.max(0, -delta / w), 1);
            } else if (this.direction === -1) {
                fraction = Math.min(Math.max(0, delta / w), 1);
            }
            this.tl.time(fraction * this.transitionDuration);
        },
        handlePanEnd(event) {
            if (this.isDisabled()) {
                return;
            }
            this.manual = false;

            // Hack: Kind of prevents event progapation. To avoid mnemonics to trigger toggleMnemonicFocus()
            this.$el.addEventListener('click', this.preventClick, true);
            setTimeout(() => {
                if (this.$el) {
                    this.$el.removeEventListener('click', this.preventClick, true);
                }
            }, 100);
            if (!this.tl) {
                return;
            }
            this.tl.play();
        },
        handlePanCancel(event) {
            if (this.isDisabled()) {
                return;
            }
            this.manual = false;
        },
        handlePress(event) {
            this.applyHack();
        },
        handlePressUp(event) {
            this.removeHack();
        },
        preventClick(event) {
            event.stopImmediatePropagation();
            event.stopPropagation();
        }
    }
};
</script>
<style lang="scss" scoped>
$padding: 2em;
.Carousel {
    display: flex;

    overflow-y: hidden;
}
.slideContainer {
    position: relative;
    width: 100%;
}
.slide {
    $padding: 0;
    position: absolute;
    top: $padding;
    right: $padding;
    bottom: $padding;
    left: $padding;
    // backface-visibility: hidden; // NOTE: what kind of hack was this?
}
.leftSide,
.rightSide {
    min-width: $padding;
    flex: 1;
}

.leftSidePlaceholder,
.rightSidePlaceholder {
    background-color: rgba(white, 0.2);
    height: 100%;
}

.leftSidePlaceholder {
    margin-right: 1em;
    border-radius: 0 0.5em 0.5em 0;
}
.rightSidePlaceholder {
    margin-left: 1em;
    border-radius: 0.5em 0 0 0.5em;
}
</style>
