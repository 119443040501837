<template>
  <div class="QuizView">
    <ProgressBar :progress="progress" />
    <GameHeader
      class="header"
      :countdown="countdown"
      :show-lives="true" />

    <div class="body">
      <transition name="fade">
        <component
          :is="currentQuizItemComponent"
          v-if="currentQuizItem"
          :key="currentQuizItem.index"
          class="QuizItem"
          :item-index="currentQuizItem.index"
          @request-next="handleRequestNext" />
      </transition>
    </div>
  </div>
</template>

<script>
import soundService from '@/services/sound-service';
import gameHelper from '@/modules/games-shared/game-helper';

import timeoutMixin from '../timeoutMixin';
import GameHeader from '../components/GameHeader';
import ProgressBar from '../components/ProgressBar';

export default {
    components: { GameHeader, ProgressBar },
    inject: ['transition', 'module', 'theme'],
    mixins: [timeoutMixin],
    data() {
        return {
            countdown: this.module.state.quiz.timeLimit
        };
    },
    computed: {
        quizSession() {
            return this.module.state.quiz;
        },
        currentQuizItem() {
            return this.module.getters('quiz/currentItem');
        },
        currentQuizItemComponent() {
            return gameHelper.sessions[this.quizSession.sessionId].quizMapping[this.currentQuizItem.type].component;
        },
        progress() {
            return this.module.getters('quiz/progress');
        }
    },
    mounted() {
        soundService.play(this.theme.sounds.themeSound, { volume: 0.5, loop: true }, 'background');
    },
    beforeDestroy() {
        soundService.stop(this.theme.sounds.themeSound, 500);
    },
    methods: {
        handleRequestNext() {
            if (this.quizSession.lives === 0) {
                this.transition('LEVEL_FAIL');
            } else if (this.quizSession.queue.length) {
                this.transition('NEXT_QUIZ_ITEM');
            } else {
                this.transition('LEVEL_SUCCEED');
            }
        },
        onTimeout() {
            this.transition('LEVEL_FAIL');
        }
    }
};
</script>

<style scoped lang="scss">
.QuizView {
    display: flex;
    flex-direction: column;
}

.body {
    position: relative;
    flex: 1;
}

.QuizItem {
    $transitionDuration: 0.5s;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &.fade-enter-active,
    &.fade-leave-active {
        transition: transform $transitionDuration;
    }
    &.fade-enter {
        transform: translate3d(100%, 0, 0);
    }
    &.fade-leave-to {
        transform: translate3d(-100%, 0, 0);
    }
}
</style>
