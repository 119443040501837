import groupBy from 'lodash-es/groupBy';

export default function generateSessionReport({ memorizeReport, quizReport, labels, initial, attempt }) {
    const items = quizReport.items.map(item => {
        const sourceItem = quizReport.sourceItems[item.sourceItemIndex];
        const testGroupsMap = groupBy(item.tests, test => test.name);
        const tests = item.testNames
            .map(testName => {
                if (!testGroupsMap[testName]) {
                    return null;
                }
                const rawAttempts = testGroupsMap[testName].filter(x => !x.skipped && x.submittedAnswer !== null);
                const attempts = rawAttempts.map(x => {
                    return attempt(x);
                });
                let fasit = null;
                if (!attempts.some(a => a.score === 1)) {
                    const lastAttempt = testGroupsMap[testName][0];
                    fasit = attempt({
                        name: lastAttempt.name,
                        submittedAnswer: lastAttempt.data.fasit
                    });
                }
                return {
                    testName,
                    attempts,
                    fasit
                };
            })
            .filter(testGroup => testGroup);
        return {
            memorizeTime: sourceItem.memorizeTime,
            quizTime: item.timeUsed,
            initial: initial(item, sourceItem),
            tests
        };
    });

    return {
        memorizeTimeUsed: memorizeReport ? memorizeReport.timeUsed : 0,
        quizTimeUsed: quizReport.timeUsed,
        stars: quizReport.stars,
        items
    };
}
