<template>
  <div
    class="TargetItem"
    :class="{ hover, matched }">
    <div
      v-if="matched"
      class="image"
      :style="{ backgroundImage: `url(${data.data.image})` }" />
    <Number
      v-else
      class="number"
      :number="data.data.number"
      :mode="data.numberingStyle" />
  </div>
</template>

<script>
import Number from '@/modules/games-shared/components/Number';

export default {
    components: { Number },
    props: {
        data: {
            type: Object,
            required: true

            // number: Number,
            // image: String,
            // name: String,
            // numberingStyle: String
        },
        // image: {
        //   type: String
        // },
        // name: String,
        matched: Boolean,
        hover: Boolean
    }
};
</script>

<style scoped lang="scss">
$borderRadius: 0.5em;

.TargetItem {
    $size: 5em;
    width: $size;
    height: $size;
    display: flex;
    align-items: center;
    border: 1px dashed rgba(black, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $borderRadius;

    transition: background-color 0.5s;

    & > * {
        pointer-events: none;
    }

    &.hover {
        background-color: rgba(white, 0.5);
    }
    &.matched {
        pointer-events: none;
        border: 1px transparent;

        animation: pulse 0.5s;

        @keyframes pulse {
            0% {
                transform: scale(1);
            }
            50% {
                transform: scale(1.1);
            }
            0% {
                transform: scale(1);
            }
        }
    }
}
.number {
    font-size: 140%;
    color: rgba(black, 0.2);
}

.image {
    $size: 100%; //4em;
    width: $size;
    height: $size;
    border-radius: $borderRadius;

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
</style>
