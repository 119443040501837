<template>
  <div
    class="NavigationWithArrows"
    :class="{ showSlot, hasSlot: hasDefaultSlot }">
    <div class="container">
      <div class="arrows">
        <div
          class="prevButton navButton --global-clickable"
          :class="{ disabled: prevDisabled }"
          @click="prev()">
          <svgicon
            class="arrowIcon"
            icon="arrow-left" />
        </div>
        <div
          class="nextButton navButton --global-clickable"
          :class="{ disabled: nextDisabled }"
          @click="next()">
          <svgicon
            class="arrowIcon"
            icon="arrow-right" />
        </div>
      </div>
      <div class="slotContainer">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        length: {
            type: Number,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        autoDisable: {
            type: String,
            default: 'both'
        }
    },
    computed: {
        prevDisabled() {
            return { both: true, left: true }[this.autoDisable] && this.index === 0;
        },
        nextDisabled() {
            return { both: true, right: true }[this.autoDisable] && this.index === this.length - 1;
        },
        hasDefaultSlot() {
            return !!this.$slots.default;
        },
        showSlot() {
            return this.hasDefaultSlot && this.nextDisabled;
        }
    },
    methods: {
        prev() {
            if (this.prevDisabled) {
                return;
            }
            this.$emit('click-prev');
        },
        next() {
            if (this.nextDisabled) {
                return;
            }
            this.$emit('click-next');
        }
    }
};
</script>

<style scoped lang="scss">
$transitionDuration: 0.5s;
$arrowsWidth: 7em;
.NavigationWithArrows {
    &.hasSlot {
    }
    &.showSlot {
        .slotContainer {
            opacity: 1;
            pointer-events: all;
        }
        .nextButton {
            opacity: 0;
        }
    }
    &:not(.showSlot) {
        .container {
            transform: translateX(calc(50% - #{$arrowsWidth / 2}));
        }
    }
}
.container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    transition: transform $transitionDuration;
    min-height: 3em;
}
.arrows {
    position: absolute;
    left: 0;
    top: 0;
    width: $arrowsWidth;
    height: 100%;
    display: flex;
    justify-content: space-between;
}
.navButton {
    display: flex;
    align-items: center;
    transition: opacity $transitionDuration;
    padding: 0 1em;

    &.disabled {
        cursor: default;
        opacity: 0.3;
    }
}
.arrowIcon {
    $size: 1.5em;
    width: $size;
    height: $size;
    fill: white;
}
.slotContainer {
    opacity: 0;
    transition: opacity $transitionDuration;
    pointer-events: none;
}
</style>
