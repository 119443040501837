<template>
  <div
    class="TargetItemLarge"
    :class="{ hover, matched }">
    <div
      v-if="matched"
      class="image"
      :style="{ backgroundImage: `url(${image})` }" />
    <AnimatedNumbershape
      v-else-if="showNumbershapeHint"
      class="numbershape"
      :number="tipNumbershape || `${number}`"
      play />

    <Number
      v-else
      class="number"
      :number="number"
      :mode="numberingStyle" />
  </div>
</template>

<script>
import AnimatedNumbershape from '@/components/AnimatedNumbershape';
import Number from '@/modules/games-shared/components/Number';

export default {
    components: { AnimatedNumbershape, Number },
    props: {
        number: {
            // type: [String, Number],
            required: true
        },
        image: {
            type: String,
            required: true
        },
        matched: {
            type: Boolean
        },
        hover: {
            type: Boolean
        },
        tipNumbershape: {
            type: String
        },
        showNumbershapeHint: {
            type: Boolean,
            default: false
        },
        numberingStyle: {
            type: String
        }
    }
};
</script>

<style scoped lang="scss">
.TargetItemLarge {
    display: flex;
    align-items: center;
    border: 1px dashed rgba(black, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5em;

    transition: background-color 0.5s;

    & > * {
        pointer-events: none;
    }

    &.hover {
        background-color: rgba(white, 0.5);
    }
    &.matched {
        pointer-events: none;
        border-color: transparent;
    }
}
.number {
    font-size: 140%;
    color: rgba(black, 0.2);
}
.numbershape {
    $size: 6em;
    width: $size;
    height: $size;
    opacity: 0.4;
}

.image {
    $size: 100%;
    width: $size;
    height: $size;
    border-radius: 0.5em;

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
</style>
