<template>
  <div class="MemorizeView">
    <GameHeader
      class="header"
      :countdown="countdown" />

    <div class="body">
      <div class="instruction">
        {{ $t('currentGame.MemorizeView.instruction') }}
      </div>
      <div> {{ items.length }} {{ $t('currentGame.MemorizeView.items') }} </div>
      <div class="items">
        <component
          :is="itemConf.component"
          v-for="(itemConf, i) of itemConfs"
          :key="i"
          class="Item"
          v-bind="itemConf.props" />
      </div>

      <div class="spacer" />
      <div
        class="continueButton --global-clickable"
        :style="{ backgroundColor: theme.primaryColor }"
        @click="startTest()">
        {{ $t('currentGame.MemorizeView.continue') }}
      </div>
    </div>
  </div>
</template>

<script>
import soundService from '@/services/sound-service';

import GameHeader from '@/modules/games-shared/components/GameHeader';
import timeoutMixin from '@/modules/games-shared/timeoutMixin';

import gameHelper from '@/modules/games-shared/game-helper';

export default {
    components: { GameHeader },
    inject: ['module', 'transition', 'theme'],
    mixins: [timeoutMixin],
    data() {
        return {
            countdown: this.module.state.memorize.timeLimit
        };
    },
    computed: {
        items() {
            return this.module.state.memorize.items;
        },
        itemConfs() {
            return this.items.map(item => {
                return {
                    component:
                        gameHelper.sessions[this.module.state.memorize.sessionId].quizMapping[item.memorizeItemType]
                            .component,
                    props: item.data
                };
            });
        }
    },
    mounted() {
        soundService.play(this.theme.sounds.themeSound, { volume: 0.5, loop: true }, 'background');
    },
    beforeDestroy() {
        soundService.stop(this.theme.sounds.themeSound, 500);
    },
    methods: {
        startTest() {
            this.transition('START_QUIZ');
        },
        onTimeout() {
            soundService.stop(this.theme.sounds.themeSound, 500);
            soundService.play(this.theme.sounds.timeupSound, { volume: 2 });
            setTimeout(() => {
                this.transition('START_QUIZ');
            }, 2000);
        }
    }
};
</script>

<style scoped lang="scss">
.MemorizeView {
    display: flex;
    flex-direction: column;
    padding-right: 1em;
}

.body {
    flex: 1;
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 100%;
    overflow-y: auto;
}

.instruction {
    font-size: 140%;
}
.items {
    align-self: stretch;
    margin-top: 2em;
}
::-webkit-scrollbar {
    display: block;
    background: inherit;
    width: 8px;
    background: #5a6f93;
    border-radius: 4px;
    padding-right: 10px;
}
::-webkit-scrollbar-thumb {
    background: #2d348f;
    border-radius: 4px;
}
.Item {
    &:not(:first-child) {
        margin-top: 0.5em;
    }
}

.spacer {
    flex: 1;
}
.continueButton {
    margin-top: 2em;
    border-radius: 0.5em;
    padding: 1em 2em;
}
</style>
