<template>
  <div class="Card">
    <div class="spacerTop" />
    <div class="card">
      <div class="number">
        <NumberComp
          :number="number"
          :mode="numberingStyle" />
      </div>
      <div class="divider" />
      <div class="spacer" />

      <div
        class="objectImage"
        :style="{ backgroundImage: `url(${image})` }" />
      <div class="objectNameAndQuantity">
        <div class="objectName">
          {{ name }}
        </div>
        <div
          v-if="quantity"
          class="objectQuantity">
          {{ quantity }} {{ unitStr }}
        </div>
      </div>
      <div
        v-if="price"
        class="objectPrice">
        {{ price }}$
      </div>
      <div class="spacer" />
    </div>

    <div
      v-if="tip"
      class="Memotip">
      <AnimatedNumbershape
        class="Memotip__numbershape"
        :number="tipNumbershape || `${number}`"
        play />

      <!-- <div class="Memotip__numbershape">{{ number }}</div> -->
      <div class="Memotip__textSection">
        <div class="Memotip__title">
          {{ $t('memotipTitle') }}
        </div>
        <div class="Memotip__text">
          {{ tip }}
        </div>
      </div>
    </div>
    <div class="spacerBottom" />
  </div>
</template>

<translations>
  // memotipTitle: How to remember
  // memotipTitle_no: Hvordan huske
  memotipTitle: Memo tips
  memotipTitle_no: Husketips
</translations>
<script>
import AnimatedNumbershape from '@/components/AnimatedNumbershape';
import NumberComp from '@/modules/games-shared/components/Number';

export default {
    components: { AnimatedNumbershape, NumberComp },
    props: {
        number: Number,
        image: String,
        name: String,
        quantity: Number,
        price: Number,
        unit: String,
        tip: String,
        tipNumbershape: String,
        numberingStyle: String
    },
    computed: {
        unitStr() {
            return this.quantity > 1 ? this.unit.replace(/[()]/g, '') : this.unit.replace(/\(.*?\)/, '');
        }
    }
};
</script>

<style scoped lang="scss">
.Card {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.spacerTop {
    flex: 0.3;
}
.spacerBottom {
    flex: 1;
}
.card {
    padding: 1em;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: rgba(white, 0.3);
    border-radius: 0.5em;

    width: 20em;
}
.number {
    font-size: 140%;
}

.divider {
    height: 1px;
    background-color: rgba(black, 0.05);
    margin: 1em 0;
    align-self: stretch;
}
.spacer {
    flex: 1;
}

.objectImage {
    $size: 8em;
    width: $size;
    height: $size;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    margin-bottom: 1em;
}

.objectNameAndQuantity {
    flex: 1;
}

.objectName {
    font-size: 120%;
}
.objectQuantity {
    margin-top: 0.3em;
    color: rgba(white, 0.7);
}

.objectPrice {
    $color: #00eb87;
    $height: 1.8em;
    height: $height;
    width: 3em;
    position: relative;
    background-color: $color;
    color: white;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
        $width: 0.5em;
        content: '';
        position: absolute;
        left: -$width;
        width: 0;
        height: 0;
        border-top: #{$height / 2} solid transparent;
        border-bottom: #{$height / 2} solid transparent;
        border-right: $width solid $color;
    }
}

.Memotip {
    margin-top: 1em;
    background-color: rgba(white, 0.8);
    border-radius: 0.5em;
    padding: 1em;
    width: 20em;

    display: flex;
    align-items: center;
    color: rgba(black, 0.8);
}
.Memotip__numbershape {
    $size: 5em;
    width: $size;
    height: $size;
    margin: auto;
    margin-right: 0.5em;
    flex-shrink: 0;
}
.Memotip__textSection {
}
.Memotip__title {
    font-weight: 600;
}
.Memotip__text {
    margin-top: 0.5em;
    line-height: 1.4em;
}
</style>
