import { Machine } from 'xstate';

const memorizeStates = {
    initial: 'loading',
    onEntry: ['memorize/enter'],
    onExit: ['memorize/leave'],
    states: {
        loading: {
            on: {
                COUNTDOWN: 'countdown'
            }
        },
        countdown: {
            on: {
                PLAY: 'playing'
            }
        },
        playing: {
            onEntry: ['memorize/enterPage'],
            onExit: ['memorize/leavePage'],
            on: {
                PREV: {
                    playing: {
                        actions: ['memorize/prev']
                    }
                },
                NEXT: {
                    playing: {
                        actions: ['memorize/next']
                    }
                }
            }
        }
    }
};

const quizItemStates = {
    initial: 'idle',
    states: {
        idle: {
            onEntry: ['quiz/enterTest'],
            onExit: ['quiz/leaveTest'],
            on: {
                SUBMIT_ANSWER: {
                    feedback: { actions: ['quiz/submitTestAnswer'] }
                }
            }
        },
        feedback: {
            on: {
                NEXT_QUIZ_ITEM_TEST: {
                    idle: { actions: [] }
                }
            }
        }
    }
};

const quizPlayStates = {
    initial: 'idle',
    states: {
        idle: {
            onEntry: ['quiz/enterIdle'],
            on: {
                ENTER_QUIZ_ITEM: 'item'
            }
        },
        item: {
            onEntry: ['quiz/enterItem'],
            onExit: ['quiz/leaveItem'],
            on: {
                LEAVE_QUIZ_ITEM: 'idle',
                SKIP_QUIZ_ITEM: {
                    idle: {
                        // actions: ['skipQuizItem']
                    }
                }
            },
            ...quizItemStates
        }
    }
};

const quizStates = {
    initial: 'loading',
    onEntry: ['quiz/enter'],
    onExit: ['quiz/leave'],
    states: {
        loading: {
            on: {
                COUNTDOWN: 'countdown'
            }
        },
        countdown: {
            on: {
                PLAY: 'playing'
            }
        },
        playing: {
            // on: {
            //   ENTER_QUIZ_ITEM: 'item'
            // }
            ...quizPlayStates
        }
    }
};

const sessionStates = {
    initial: 'memorize',
    states: {
        memorize: {
            on: {
                START_QUIZ: 'quiz'
            },
            ...memorizeStates
        },
        quiz: {
            on: {
                LEVEL_FAIL: 'fail',
                LEVEL_SUCCEED: 'success'
            },
            ...quizStates
        },
        fail: {
            // id: 'sessionFail'
        },
        success: {}
    }
};

const machine = Machine({
    initial: 'initial',
    strict: true,
    states: {
        initial: {
            on: {
                START: 'session'
            }
        },
        session: {
            on: {
                EXIT_TO_INTRO: 'exit'
            },
            ...sessionStates
        },
        exit: {}
    }
});

export { machine };
