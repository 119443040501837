<template>
  <div class="ListItem">
    <div class="number">
      <Number
        :number="number"
        :mode="numberingStyle" />
      <!-- {{ number }} -->
      <!-- <span class="number--letter">{{ 'abcdefghijklmenopqrstuvwxyz'[number - 1] }}</span> -->
    </div>
    <div class="divider" />
    <div class="object">
      <div
        class="objectImage"
        :style="{ backgroundImage: `url(${image})` }" />
      <div class="objectNameAndQuantity">
        <div class="objectName">
          {{ name }}
        </div>

        <div
          v-if="quantity"
          class="objectQuantity">
          {{ quantity }} {{ unitStr }}
        </div>
      </div>
      <div
        v-if="price"
        class="objectPrice">
        {{ price }}$
      </div>
    </div>
  </div>
</template>

<script>
import Number from '@/modules/games-shared/components/Number';

export default {
    components: { Number },
    props: {
        number: Number,
        image: String,
        name: String,
        quantity: Number,
        price: Number,
        unit: String,
        numberingStyle: String
    },
    computed: {
        unitStr() {
            return this.quantity > 1 ? this.unit.replace(/[()]/g, '') : this.unit.replace(/\(.*?\)/, '');
        },
        label() {
            return 'abcdefghijklmenopqrstuvwxyz'[this.number - 1];
            // return this.number
        }
    }
};
</script>

<style scoped lang="scss">
.ListItem {
    display: flex;
    align-items: center;

    background-color: rgba(white, 0.3);
    // padding: 0.5em 0;
    // padding-left: 0;
    border-radius: 0.5em;
}
.number {
    $size: 2em;
    width: $size;
    height: $size;
    border-radius: 50%;
    // width: 2em;
    // text-align: right;
    margin-right: 0.2em;
    color: rgba(black, 0.5);
    // background-color: white;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 0.5em;
}
.divider {
    background-color: rgba(black, 0.05);
    width: 1px;
    align-self: stretch;
    margin: 0em;
    margin-left: 0;
}
.object {
    display: flex;
    align-items: center;
    // padding: 0.5em 1em;
    // border-radius: 0.5em;
    // background-color: rgba(white, 0.3);
    color: white;
    flex: 1;
    padding: 0.5em 0;
}
.objectImage {
    $size: 5em;
    width: $size;
    height: $size;
    margin-right: 1em;
    margin-left: 1em;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.objectNameAndQuantity {
    flex: 1;
}

.objectName {
    font-size: 120%;
}
.objectQuantity {
    margin-top: 0.3em;
    color: rgba(white, 0.7);
}

.objectPrice {
    $color: #00eb87;
    $height: 1.8em;
    height: $height;
    width: 3em;
    position: relative;
    background-color: $color;
    color: white;
    font-weight: 600;
    // padding: 0 0.8em;
    display: flex;
    justify-content: center;
    align-items: center;
    // border-radius: 0.2em;

    &::before {
        $width: 0.5em;
        content: '';
        position: absolute;
        left: -$width;
        width: 0;
        height: 0;
        border-top: #{$height / 2} solid transparent;
        border-bottom: #{$height / 2} solid transparent;
        border-right: $width solid $color;
    }
}
</style>
