import QuizItem from '@/modules/games-shared/components/QuizItem';

import Session from './Session';
import questionService from './question-service';
import quizModule from './quiz-module';

import ListItem from './components/ListItem';
import Card from './components/Card';
import MemorizeItemInstruction from './components/MemorizeItemInstruction';
import Question from './components/Question';
import MatchTest from './components/MatchTest';
import QuantityTest from './components/QuantityTest';
import PriceTest from './components/PriceTest';

import MemorizeView from './views/MemorizeView';
import QuizView from './views/QuizView';

import { machine } from './machine';

const quizMapping = {
    'GameShopping-MemorizeItem': {
        component: ListItem
    },
    'GameShopping-MemorizeItemLarge': {
        component: Card,
        instructionComponent: MemorizeItemInstruction
    },
    'GameShopping-QuizItem': {
        component: QuizItem,
        questionComponent: Question,
        generateTestNames(data) {
            const testNames = [];
            testNames.push('matchTest');
            if (data.quantity) {
                testNames.push('quantityTest');
            }
            if (data.price) {
                testNames.push('priceTest');
            }
            return testNames;
        },
        tests: {
            matchTest: {
                skipItemOnWrong: true,
                isInvisible: true,
                component: MatchTest,
                generateTestData(data) {
                    return {
                        fasit: data.number
                    };
                },
                calculateScore(data, answer) {
                    return data.number === answer ? 1 : 0;
                }
            },
            quantityTest: {
                component: QuantityTest,
                generateTestData(data) {
                    const options = questionService.getQuantities(4, [data.quantity]);
                    return {
                        fasit: data.quantity,
                        type: 'MultipleChoice',
                        requiredChoiceCount: 1,
                        options
                    };
                },
                calculateScore(data, answer) {
                    return data.quantity === answer ? 1 : 0;
                }
            },
            priceTest: {
                component: PriceTest,
                generateTestData(data) {
                    const options = questionService.getPrices(4, [data.price]);
                    return {
                        fasit: data.price,
                        type: 'MultipleChoice',
                        requiredChoiceCount: 1,
                        options
                    };
                },
                calculateScore(data, answer) {
                    return data.price === answer ? 1 : 0;
                }
            }
        }
    }
};

export default {
    moduleName: 'moduleGameShopping',
    Session,
    quizModule,
    quizMapping,
    internalRouterOptions: {
        MemorizeView,
        QuizView
    },
    machine
};
