<template>
  <div class="SessionContainer">
    <transition name="fade">
      <router-view
        class="view"
        @game-session-done="onDone" />
    </transition>
  </div>
</template>

<script>
import VueRouter from 'vue-router';
import store from '@/store';
import soundService from '@/services/sound-service';
import gameHelper from '@/modules/games-shared/game-helper';

import wrapVuexModule from './wrapVuexModule';
import wrapTransition from './wrapTransition';
import commonRoutes from './common-routes';
import { machine } from './common-machine';

import MemorizeView from './views/MemorizeView';
import QuizView from './views/QuizView';

export default {
    props: {
        sessionId: {
            type: String,
            required: true
        }
    },
    router: new VueRouter({
        mode: 'abstract'
    }),
    inject: ['theme'],
    provide() {
        return {
            module: this.module,
            transition: this.transition
        };
    },
    computed: {
        currentPath() {
            return this.module.getters('currentPath');
        }
    },
    watch: {
        currentPath: {
            immediate: true,
            handler(newVal, oldVal) {
                this.$router.push('/' + newVal);
            }
        }
    },
    beforeCreate() {
        const options = this.$options.config;
        const sessionId = this.$options.propsData.sessionId;
        this.config = options;
        // this.module = wrapVuexModule(store, options.moduleName, sessionId)
        this.module = wrapVuexModule(store, options.moduleName + '/s');
        this.module.commit('setMachineState', 'initial');
        this.transition = wrapTransition(options.moduleName, sessionId, options.machine || machine);
    },
    async created() {
        const options = this.config;
        this.module.commit('setMachineState', 'initial');

        let memorizeViewComponent =
            (options.internalRouterOptions && options.internalRouterOptions.MemorizeView) || MemorizeView;
        // NOTE: Hack, relevant for shopping game
        const session = gameHelper.sessions[this.sessionId];
        if (session.options.memorizeSingleCardMode) {
            memorizeViewComponent = MemorizeView;
        }

        // NOTE: We need to start with a clean router, else addRoutes keep adding to the same instance
        const cleanRouter = new VueRouter({
            mode: 'abstract',
            routes: [...commonRoutes]
        });
        this.$router.matcher = cleanRouter.matcher;
        this.$router.addRoutes([
            {
                path: '/session/memorize/playing',
                name: 'memorize',
                component: memorizeViewComponent
            },
            {
                path: '/session/quiz/playing/*',
                name: 'quiz',
                component: (options.internalRouterOptions && options.internalRouterOptions.QuizView) || QuizView
            },
            ...(options.internalRouterOptions ? options.internalRouterOptions.extraRoutes || [] : [])
        ]);

        this.module.commit('setAppIsLoading', true);
        await soundService.preload(Object.values(this.theme.sounds));
        this.module.commit('setAppIsLoading', false);

        this.transition('START');
    },
    methods: {
        onDone(signal) {
            this.$emit('game-session-done', signal);
        }
    }
};
</script>

<style scoped lang="scss">
.SessionContainer {
}
.view {
    height: 100%;

    $transitionDuration: 1s;
    &.fade-enter-active,
    &.fade-leave-active {
        transition: opacity $transitionDuration, transform $transitionDuration;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    &.fade-enter {
        opacity: 0;
        transform: translate3d(0, 4em, 0);
    }
    &.fade-leave-to {
        opacity: 0;
        transform: translate3d(0, -4em, 0);
    }
}
</style>
