import loadImage from 'image-promise';
import shuffle from 'lodash-es/shuffle';

import generateSessionReport from '@/modules/memogenius-game/generateSessionReport';

import questionService from './question-service';
import getLevelOptions from './get-level-options';

export default class Session {
    constructor(options) {
        if (options.levelIndex !== undefined) {
            this.levelIndex = options.levelIndex;
            this.options = getLevelOptions(this.levelIndex);
        } else {
            this.options = options;
        }
    }
    async generateMemorize() {
        const {
            numberOfItems,
            numberOfFakeItems = 0,
            includeQuantity,
            includePrice,
            itemMemorizeTimeLimit,
            items: itemOptions = null,
            memorizeSingleCardMode = false,
            numberingStyle
        } = this.options;
        const memorizeTimeLimit = itemMemorizeTimeLimit * numberOfItems;

        this.allItems =
            itemOptions ||
            questionService.getItems(numberOfItems + numberOfFakeItems, {
                includeQuantity,
                includePrice
            });
        const items = this.allItems.slice(0, numberOfItems).map(xx => {
            return {
                data: xx,
                memorizeItemType: memorizeSingleCardMode
                    ? 'GameShopping-MemorizeItemLarge'
                    : 'GameShopping-MemorizeItem',
                timeLimit: itemMemorizeTimeLimit
            };
        });
        if (numberingStyle) {
            items.forEach(item => {
                item.data.numberingStyle = numberingStyle;
            });
        }

        await loadImage(items.map(item => item.data.image)).catch(function (err) {
            console.error('One or more images have failed to load :('); // eslint-disable-line no-console
            console.error(err.errored); // eslint-disable-line no-console
            console.info('But these loaded fine:'); // eslint-disable-line no-console
            console.info(err.loaded); // eslint-disable-line no-console
        });

        return {
            items,
            timeLimit: memorizeTimeLimit,
            pageSize: memorizeSingleCardMode ? 1 : numberOfItems,
            timeoutPerItem: true
        };
    }
    reportMemorize({ items, timeUsed }) {
        this.memorizeReport = { items, timeUsed };
    }
    async generateQuiz() {
        const {
            numberOfItems,
            itemQuizTimeLimit,
            disableLives = false,
            quizShowQuestionsSequentially = false,
            quizRandomizeQuestionOrder = false,
            quizShowNumbershapeHints = false,
            numberingStyle
        } = this.options;
        const quizTimeLimit = itemQuizTimeLimit * numberOfItems;

        const items = this.memorizeReport ? this.memorizeReport.items : (await this.generateMemorize()).items;
        const targetItems = items.map((item, i) => {
            return {
                quizItemType: 'GameShopping-QuizItem',
                data: item.data,
                match: false,
                memorizeTime: item.timeUsed,
                showNumbershapeHint: quizShowNumbershapeHints,
                numberingStyle
            };
        });

        await loadImage(this.allItems.map(item => item.image)).catch(function (err) {
            console.error('One or more images have failed to load :('); // eslint-disable-line no-console
            console.error(err.errored); // eslint-disable-line no-console
            console.info('But these loaded fine:'); // eslint-disable-line no-console
            console.info(err.loaded); // eslint-disable-line no-console
        });

        return {
            shelfItems: shuffle(
                this.allItems.map(item => {
                    return {
                        id: item.number,
                        number: item.number,
                        image: item.image,
                        dragging: false,
                        match: false
                    };
                })
            ),
            targetItems: quizRandomizeQuestionOrder ? shuffle(targetItems) : targetItems,
            sourceItems: this.allItems,
            timeLimit: quizTimeLimit,
            lives: disableLives ? -1 : 3,
            sequentialMode: quizShowQuestionsSequentially
        };
    }
    reportQuiz({ items, timeUsed, score, sourceItems }) {
        const { numberOfItems, stars2, stars3 } = this.options;

        const memorizeTime = this.memorizeReport && this.memorizeReport.timeUsed;
        let stars = 0;
        // NOTE: should not give stars if score < 1. But score wrongely reported, so ignored
        if (memorizeTime) {
            if (memorizeTime < stars3 * numberOfItems * 1000) {
                stars = 3;
            } else if (memorizeTime < stars2 * numberOfItems * 1000) {
                stars = 2;
            } else {
                stars = 1;
            }
        }

        this.quizReport = { items, timeUsed, stars, sourceItems };
    }
    generateReport() {
        const getImage = number => {
            return this.allItems[number - 1].image;
        };
        const sourceItems = this.allItems;
        return generateSessionReport({
            memorizeReport: this.memorizeReport,
            quizReport: this.quizReport,
            initial(item, sourceItem) {
                return { type: 'number', value: sourceItem.data.number };
            },
            attempt(test) {
                if (test.name === 'matchTest') {
                    const itemData = sourceItems.find(x => x.number === test.submittedAnswer);
                    return {
                        type: 'image',
                        value: { image: getImage(test.submittedAnswer), label: itemData.name },
                        score: test.score
                    };
                } else if (test.name === 'quantityTest') {
                    return {
                        value: test.submittedAnswer,
                        score: test.score
                    };
                } else if (test.name === 'priceTest') {
                    return {
                        value: test.submittedAnswer,
                        score: test.score
                    };
                }
            }
        });
    }

    generateSessionOptionsForRetry() {
        return Object.assign({}, this.options, {});
    }
}
