<template>
  <div class="QuizItem">
    <component
      :is="questionComponent"
      class="Question"
      :item-index="itemIndex"
      @feedback-completed="handleQuestionFeedbackCompleted" />
    <transition
      name="fade"
      mode="out-in">
      <component
        :is="currentQuizItemTestComponent"
        :key="quizItem.tests.length"
        ref="test"
        class="TEST"
        v-bind="currentQuizItemTest"
        @request-submit="handleSubmit"
        @feedback-completed="handleFeedbackCompleted" />
    </transition>

    <div class="footer">
      <div
        class="skip --global-clickable"
        :class="{ disabled: !canSkip }"
        @click="skipQuestion()">
        {{ $t('skip') }}
      </div>
    </div>
  </div>
</template>

<translations>
  skip: 'I don''t remember. Skip this'
  skip_no: 'Jeg husker ikke. Hopp over'
</translations>

<script>
import last from 'lodash-es/last';
import gameHelper from '@/modules/games-shared/game-helper';

export default {
    name: 'QuizItem',
    props: {
        itemIndex: {
            type: Number,
            required: true
        }
    },
    inject: ['transition', 'module', 'theme'],
    computed: {
        quizSession() {
            return this.module.state.quiz;
        },
        quizItem() {
            return this.module.getters('quiz/getItem')(this.itemIndex);
        },
        currentQuizItemTest() {
            return last(this.quizItem.tests);
        },
        currentQuizItemTestComponent() {
            return gameHelper.sessions[this.quizSession.sessionId].quizMapping[this.quizItem.type].tests[
                this.currentQuizItemTest.name
            ].component;
        },
        questionComponent() {
            return gameHelper.sessions[this.quizSession.sessionId].quizMapping[this.quizItem.type].questionComponent;
        },
        question() {
            return this.quizSession.sourceItems[this.quizItem.sourceItemIndex].data;
        },
        canSkip() {
            return this.quizSession.queue && this.quizSession.queue.length;
        },
        hasMoreTests() {
            if (
                this.currentQuizItemTest.score === 0 &&
                gameHelper.sessions[this.quizSession.sessionId].quizMapping[this.quizItem.type].tests[
                    this.currentQuizItemTest.name
                ].skipItemOnWrong
            ) {
                return false;
            }
            if (
                this.currentQuizItemTest.score === 1 &&
                this.currentQuizItemTest.name === last(this.quizItem.testNames)
            ) {
                return false;
            }
            return true;
        }
    },
    methods: {
        handleSubmit(answer) {
            this.transition('SUBMIT_ANSWER', { answer });
        },
        handleFeedbackCompleted() {
            if (this.quizSession.lives > 0 && this.hasMoreTests) {
                this.transition('NEXT_QUIZ_ITEM_TEST');
            } else {
                this.$emit('request-next');
            }
        },
        handleQuestionFeedbackCompleted() {
            // if (this.quizSession.lives === 0) {
            //   this.transition('LEVEL_FAIL')
            // } else if (this.hasMoreTests) {
            //   this.transition('NEXT_QUIZ_ITEM_TEST')
            // } else {
            //   this.$emit('request-next')
            // }
        },
        skipQuestion() {
            // this.transition('SKIP_QUIZ_ITEM')
            this.module.dispatch('quiz/skipItem');
            this.$emit('request-next');
        }
    }
};
</script>

<style scoped lang="scss">
.QuizItem {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > * {
        flex-shrink: 0;
    }
}

.TEST {
    align-self: stretch;
    flex: 1;
    $transitionDuration: 0.5s;
    &.fade-enter-active,
    &.fade-leave-active {
        transition: transform $transitionDuration, opacity $transitionDuration;
    }
    &.fade-enter {
        transform: translate3d(0, 1em, 0);
        opacity: 0;
    }
    &.fade-leave-to {
        transform: translate3d(0, 1em, 0);
        opacity: 0;
    }
}

.Question {
    align-self: stretch;
}

.footer {
    padding: 2em;
}

.skip {
    color: rgba(white, 0.5);
    &.disabled {
        visibility: hidden;
    }
}
</style>
