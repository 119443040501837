export default {
    en: [
        { name: 'eggs', unit: 'piece(s)', id: 1 },
        { name: 'bell pepper', unit: 'piece(s)', id: 2 },
        { name: 'tomatoes', unit: 'piece(s)', id: 3 },
        { name: 'eggplants', unit: 'piece(s)', id: 4 },
        { name: 'broccoli', unit: 'piece(s)', id: 5 },
        { name: 'onions', unit: 'piece(s)', id: 6 },
        { name: 'strawberries', unit: 'basket(s)', id: 7 },
        { name: 'pine apples', unit: 'piece(s)', id: 8 },
        { name: 'grapes', unit: 'basket(s)', id: 9 },
        { name: 'lemons', unit: 'piece(s)', id: 10 },
        { name: 'bananas', unit: 'piece(s)', id: 11 },
        { name: 'green apples', unit: 'piece(s)', id: 12 },
        { name: 'cucumbers', unit: 'piece(s)', id: 13 },
        { name: 'water melon', unit: 'piece(s)', id: 14 },
        { name: 'beer', unit: 'bottle(s)', id: 15 },
        { name: 'hamburgers', unit: 'piece(s)', id: 16 },
        { name: 'caviar', unit: 'basket(s)', id: 17 },
        { name: 'pepper', unit: 'piece(s)', id: 18 },
        { name: 'kiwis', unit: 'piece(s)', id: 19 },
        { name: 'apples', unit: 'piece(s)', id: 20 },
        { name: 'mangoes', unit: 'piece(s)', id: 21 },
        { name: 'rock melon', unit: 'piece(s)', id: 22 },
        { name: 'cherries', unit: 'basket(s)', id: 23 },
        { name: 'sausages', unit: 'piece(s)', id: 24 },
        { name: 'pears', unit: 'piece(s)', id: 25 },
        { name: 'oranges', unit: 'piece(s)', id: 26 },
        { name: 'peaches', unit: 'piece(s)', id: 27 },
        { name: 'apricots', unit: 'piece(s)', id: 28 },
        { name: 'garlic', unit: 'piece(s)', id: 29 },
        { name: 'mushrooms', unit: 'basket(s)', id: 30 },
        { name: 'carrots', unit: 'piece(s)', id: 31 },
        { name: 'radish', unit: 'piece(s)', id: 32 },
        { name: 'corn', unit: 'piece(s)', id: 33 },
        { name: 'beetroots', unit: 'piece(s)', id: 34 },
        { name: 'chili', unit: 'basket(s)', id: 35 },
        { name: 'potatoes', unit: 'bag(s)', id: 36 },
        { name: 'rice', unit: 'bag(s)', id: 37 },
        { name: 'cereal', unit: 'box(es)', id: 38 },
        { name: 'pasta', unit: 'package(es)', id: 39 },
        { name: 'sweet potatoes', unit: 'bag(s)', id: 40 },
        { name: 'ladyfingers', unit: 'bag(s)', id: 41 },
        { name: 'bread', unit: 'piece(s)', id: 42 },
        { name: 'salami', unit: 'piece(s)', id: 43 },
        { name: 'pomegranate', unit: 'piece(s)', id: 44 },
        { name: 'cauliflower', unit: 'piece(s)', id: 45 },
        { name: 'beef', unit: 'piece(s)', id: 46 },
        { name: 'avocado', unit: 'piece(s)', id: 47 },
        { name: 'cabbage', unit: 'piece(s)', id: 48 },
        { name: 'milk', unit: 'liter(s)', id: 49 },
        { name: 'cheese', unit: 'piece(s)', id: 50 },
        { name: 'chicken', unit: 'piece(s)', id: 51 },
        { name: 'cake', unit: 'piece(s)', id: 52 },
        { name: 'walnuts', unit: 'bag(s)', id: 53 },
        { name: 'olive oil', unit: 'bottle(s)', id: 54 },
        { name: 'fish', unit: 'piece(s)', id: 55 },
        { name: 'pizza', unit: 'box(es)', id: 56 },
        { name: 'salt', unit: 'bag(s)', id: 57 },
        { name: 'sugar', unit: 'bag(s)', id: 58 },
        { name: 'sunscreen', unit: 'bottle(s)', id: 59 },
        { name: 'vinegar', unit: 'bottle(s)', id: 60 },
        { name: 'butter', unit: 'box(es)', id: 61 },
        { name: 'mandarines', unit: 'bag(s)', id: 62 },
        { name: 'blueberries', unit: 'basket(s)', id: 63 },
        { name: 'lime', unit: 'piece(s)', id: 64 },
        { name: 'soap', unit: 'piece(s)', id: 65 },
        { name: 'coconuts', unit: 'piece(s)', id: 66 },
        { name: 'toilet paper', unit: 'roll(s)', id: 67 },
        { name: 'wine', unit: 'bottle(s)', id: 68 },
        { name: 'light bulbs', unit: 'piece(s)', id: 69 },
        { name: 'chips', unit: 'bag(s)', id: 70 },
        { name: 'tea', unit: 'box(es)', id: 71 },
        { name: 'chocolate', unit: 'piece(s)', id: 72 },
        { name: 'tomato sauce', unit: 'jar(s)', id: 73 },
        { name: 'olives', unit: 'jar(s)', id: 74 },
        { name: 'ginger', unit: 'piece(s)', id: 75 }
    ],
    no: [
        { name: 'egg', unit: 'stykk(er)', id: 1 },
        { name: 'paprika', unit: 'stykk(er)', id: 2 },
        { name: 'tomater', unit: 'stykk(er)', id: 3 },
        { name: 'aubergine', unit: 'stykk(er)', id: 4 },
        { name: 'brokolli', unit: 'stykk(er)', id: 5 },
        { name: 'løk', unit: 'stykk(er)', id: 6 },
        { name: 'jordbær', unit: 'kurv(er)', id: 7 },
        { name: 'ananas', unit: 'stykk(er)', id: 8 },
        { name: 'druer', unit: 'kurv(er)', id: 9 },
        { name: 'sitroner', unit: 'stykk(er)', id: 10 },
        { name: 'bananer', unit: 'stykk(er)', id: 11 },
        { name: 'grønne epler', unit: 'stykk(er)', id: 12 },
        { name: 'agurk', unit: 'stykk(er)', id: 13 },
        { name: 'vannmelon', unit: 'stykk(er)', id: 14 },
        { name: 'øl', unit: 'flaske(r)', id: 15 },
        { name: 'hamburger', unit: 'stykk(er)', id: 16 },
        { name: 'kaviar', unit: 'kurv(er)', id: 17 },
        { name: 'pepper', unit: 'stykk(er)', id: 18 },
        { name: 'kiwi', unit: 'stykk(er)', id: 19 },
        { name: 'epler', unit: 'stykk(er)', id: 20 },
        { name: 'mango', unit: 'stykk(er)', id: 21 },
        { name: 'honningmelon', unit: 'stykk(er)', id: 22 },
        { name: 'kirsebær', unit: 'kurv(er)', id: 23 },
        { name: 'pølser', unit: 'stykk(er)', id: 24 },
        { name: 'pærer', unit: 'stykk(er)', id: 25 },
        { name: 'appelsiner', unit: 'stykk(er)', id: 26 },
        { name: 'fersken', unit: 'stykk(er)', id: 27 },
        { name: 'aprikos', unit: 'stykk(er)', id: 28 },
        { name: 'hvitløk', unit: 'stykk(er)', id: 29 },
        { name: 'sopp', unit: 'kurv(er)', id: 30 },
        { name: 'gulrot', unit: 'stykk(er)', id: 31 },
        { name: 'redikk', unit: 'stykk(er)', id: 32 },
        { name: 'mais', unit: 'stykk(er)', id: 33 },
        { name: 'rødbeter', unit: 'stykk(er)', id: 34 },
        { name: 'chilli', unit: 'kurv(er)', id: 35 },
        { name: 'poteter', unit: 'pose(r)', id: 36 },
        { name: 'ris', unit: 'pose(r)', id: 37 },
        { name: 'frokostblanding', unit: 'pakke(r)', id: 38 },
        { name: 'pasta', unit: 'pakke(r)', id: 39 },
        { name: 'søtpotet', unit: 'pose(r)', id: 40 },
        { name: 'ladyfingers', unit: 'pose(r)', id: 41 },
        { name: 'brød', unit: 'stykk(er)', id: 42 },
        { name: 'salami', unit: 'stykk(er)', id: 43 },
        { name: 'granateple', unit: 'stykk(er)', id: 44 },
        { name: 'blomkål', unit: 'stykk(er)', id: 45 },
        { name: 'biff', unit: 'stykk(er)', id: 46 },
        { name: 'avokado', unit: 'stykk(er)', id: 47 },
        { name: 'kål', unit: 'stykk(er)', id: 48 },
        { name: 'melk', unit: 'liter(s)', id: 49 },
        { name: 'ost', unit: 'stykk(er)', id: 50 },
        { name: 'kylling', unit: 'stykk(er)', id: 51 },
        { name: 'kake', unit: 'stykk(er)', id: 52 },
        { name: 'valnøtter', unit: 'pose(r)', id: 53 },
        { name: 'olivenolje', unit: 'flaske(r)', id: 54 },
        { name: 'fisk', unit: 'stykk(er)', id: 55 },
        { name: 'pizza', unit: 'pakke(r)', id: 56 },
        { name: 'salt', unit: 'pose(r)', id: 57 },
        { name: 'sukker', unit: 'pose(r)', id: 58 },
        { name: 'solkrem', unit: 'flaske(r)', id: 59 },
        { name: 'eddik', unit: 'flaske(r)', id: 60 },
        { name: 'smør', unit: 'pakke(r)', id: 61 },
        { name: 'mandariner', unit: 'pose(r)', id: 62 },
        { name: 'blåbær', unit: 'kurv(er)', id: 63 },
        { name: 'lime', unit: 'stykk(er)', id: 64 },
        { name: 'såpe', unit: 'stykk(er)', id: 65 },
        { name: 'kokosnøtter', unit: 'stykk(er)', id: 66 },
        { name: 'toalettpapir', unit: 'roll(s)', id: 67 },
        { name: 'vin', unit: 'flaske(r)', id: 68 },
        { name: 'lyspærer', unit: 'stykk(er)', id: 69 },
        { name: 'chips', unit: 'pose(r)', id: 70 },
        { name: 'te', unit: 'pakke(r)', id: 71 },
        { name: 'sjokolade', unit: 'stykk(er)', id: 72 },
        { name: 'tomatsaus', unit: 'glass', id: 73 },
        { name: 'oliven', unit: 'glass', id: 74 },
        { name: 'ingefær', unit: 'stykk(er)', id: 75 }
    ]
};
