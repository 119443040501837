<template>
  <div class="Question">
    <div
      class="question"
      :style="{ color: theme.primaryColor }">
      <div v-if="currentTestName === 'quantityTest'">
        Pick the right quantity
      </div>
      <div v-else-if="currentTestName === 'priceTest'">
        Pick the right price
      </div>
    </div>

    <div>
      <ListItem
        class="contextCard"
        v-bind="context" />
    </div>
  </div>
</template>

<script>
import last from 'lodash-es/last';
import ListItem from './ListItem';

export default {
    components: { ListItem },
    props: {
        itemIndex: {
            type: Number,
            required: true
        }
    },
    inject: ['module', 'theme'],
    computed: {
        doneMap() {
            const map = {};
            this.quizItem.tests.forEach(t => {
                map[t.name] = t.score;
            });
            return map;
        },
        session() {
            return this.module.state;
        },
        quizItem() {
            return last(this.session.quiz.items);
        },
        currentTestName() {
            return last(this.quizItem.tests).name;
        },
        question() {
            return this.session.quiz.sourceItems[this.quizItem.sourceItemIndex];
        },
        context() {
            return {
                number: this.question.number,
                image: this.question.image,
                name: this.question.name,
                quantity: this.doneMap.quantityTest ? this.question.quantity : undefined,
                price: this.doneMap.priceTest ? this.question.price : undefined,
                unit: this.question.unit
            };
        }
    }
};
</script>

<style scoped lang="scss">
.Question {
    padding: 2em;
    padding-top: 1em;
}
.wordTestQuestion {
    font-size: 120%;
    text-align: center;
}
.question {
    font-size: 120%;
    text-align: center;
}

.contextCard {
    margin-top: 2em;
    margin-bottom: 2em;
}
</style>
