<template>
  <div
    v-if="mode === 'number'"
    class="Number">
    {{ number }}
  </div>
  <div
    v-else-if="mode === 'letter'"
    class="Number">
    {{ letter }}
  </div>
  <div
    v-else-if="mode === 'letter-and-number'"
    class="Number">
    {{ letter }}<span class="letternum">{{ number }}</span>
  </div>
</template>

<translations>
  alphabet: 'abcdefghijklmnopqrstuvwxyz'
  alphabet_no: 'abcdefghijklmnopqrstuvwxyzæøå'
</translations>

<script>
export default {
    props: {
        number: {
            type: Number,
            required: true
        },
        mode: {
            type: String,
            default: 'number',
            validator: function (value) {
                return ['number', 'letter', 'letter-and-number'].indexOf(value) !== -1;
            }
        }
    },
    computed: {
        alphabet() {
            return this.$t('alphabet');
        },
        letter() {
            return this.alphabet[this.number - 1] || this.number;
        }
    }
};
</script>

<style scoped lang="scss">
$size: 8em;
.Number {
    display: inline;
    text-transform: uppercase;
}
.letternum {
    font-size: 70%;
    opacity: 0.8;
    margin-left: 0.2em;
}
</style>
