<template>
  <div
    class="SoundSettingsButton --global-clickable"
    @click="showSoundSettings()">
    <svgicon
      v-if="backgroundSoundVolume === 0"
      class="soundIcon"
      icon="sound-mute" />
    <svgicon
      v-else-if="backgroundSoundVolume < 1"
      class="soundIcon"
      icon="sound-low" />
    <svgicon
      v-else
      class="soundIcon"
      icon="sound-high" />
  </div>
</template>

<script>
import SoundSettingsPopup from '@/modules/memogenius-game/components/SoundSettingsPopup';

export default {
    props: {
        countdown: {
            type: Number,
            default: 0
        },
        showLives: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        backgroundSoundVolume() {
            return this.$store.state.moduleMemogenius.settings.backgroundSoundVolume;
        }
    },
    methods: {
        showSoundSettings() {
            this.$modal.open({
                component: SoundSettingsPopup,
                target: 'memogenius-game-dropdown',
                hostOptions: {
                    pointAt: this.$el
                }
            });
        }
    }
};
</script>

<style scoped lang="scss">
$iconColor: rgba(white, 0.8);
.SoundSettingsButton {
    $size: 2em;
    width: $size;

    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
}
.soundIcon {
    $size: 1em;
    width: $size;
    height: $size;
    fill: $iconColor;
}
</style>
