<template>
  <div class="MatchTest" />
</template>

<script>
import soundService from '@/services/sound-service';
import correctSound from '@/assets/sounds/correct-answer.mp3';
import { TimelineMax, Power2 } from 'gsap';

export default {
    inject: ['transition'],
    props: {
        done: {
            type: Boolean
        },
        score: {
            type: Number
        },
        prepopulatedAnswer: {
            type: Number
        },
        data: {
            type: Object
        }
    },
    watch: {
        done() {
            this.$nextTick(() => {
                if (this.score === 1) {
                    soundService.play(correctSound);
                    this.playFeedbackCorrect().then(() => {
                        this.$emit('feedback-completed');
                    });
                } else {
                    this.playFeedbackWrong().then(() => {
                        this.$emit('feedback-completed');
                    });
                }
            });
        }
    },
    mounted() {
        this.submit();
    },
    methods: {
        playFeedbackWrong() {
            this.done = true;

            return new Promise(resolve => {
                const elementToAnimate = document.querySelector('.TargetItemLarge');
                if (!elementToAnimate) {
                    return resolve();
                }

                const shakeSpeed = 0.1;

                let tl = (this.tl = new TimelineMax({ onComplete: () => resolve() }));
                tl.to(elementToAnimate, shakeSpeed, {
                    x: -7,
                    ease: Power2.easeInOut
                });
                tl.to(elementToAnimate, shakeSpeed, {
                    repeat: 4,
                    x: 5,
                    yoyo: true,
                    ease: Power2.easeInOut
                });
                tl.to(elementToAnimate, shakeSpeed, {
                    x: 0
                });
            });
        },
        playFeedbackCorrect() {
            this.done = true;
            return new Promise(resolve => {
                const elementToAnimate = document.querySelector('.TargetItemLarge');
                if (!elementToAnimate) {
                    return resolve();
                }

                const tl = (this.tl = new TimelineMax({ onComplete: () => resolve() }));
                tl.to(elementToAnimate, 0.25, {
                    scale: 1.1,
                    ease: Power2.easeInOut
                });
                tl.to(elementToAnimate, 0.25, {
                    scale: 1,
                    ease: Power2.easeInOut
                });
            });
        },
        submit() {
            this.transition('SUBMIT_ANSWER', { value: this.prepopulatedAnswer });
        }
    }
};
</script>

<style scoped lang="scss">
.MatchTest {
}
</style>
