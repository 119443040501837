<template>
  <div class="GameHeader">
    <div class="leftSlot">
      <Lives
        v-if="showLives && remainingLives > -1"
        :remaining="remainingLives" />
    </div>
    <Countdown
      v-if="countdown >= 0"
      class="countdown"
      :value="countdown" />
    <div class="rightSlot">
      <SoundSettingsButton />

      <div
        class="exitButton --global-clickable"
        @click="exit()">
        <svgicon
          class="exitIcon"
          icon="exit" />
      </div>
    </div>
  </div>
</template>

<script>
import SoundSettingsButton from './SoundSettingsButton';
import Countdown from './Countdown';
import Lives from './Lives';

export default {
    components: { Countdown, Lives, SoundSettingsButton },
    props: {
        countdown: {
            type: Number,
            default: 0
        },
        showLives: {
            type: Boolean,
            default: false
        }
    },
    inject: {
        module: { default: null },
        transition: { default: null }
    },
    computed: {
        remainingLives() {
            return this.module.state.quiz.lives;
        }
    },
    methods: {
        exit() {
            if (this.transition) {
                this.transition('EXIT_TO_INTRO');
            } else {
                this.$emit('request-exit');
            }
        }
    }
};
</script>

<style scoped lang="scss">
$iconColor: rgba(white, 0.8);
.GameHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $memogeniusShared-headerHeight;
    flex-shrink: 0;
}

.leftSlot,
.rightSlot {
    flex: 1;
}
.rightSlot {
    display: flex;
    justify-content: flex-end;
    align-self: stretch;
}
.exitButton {
    align-self: stretch;
    padding-left: 1em;
    padding-right: 2em;
    color: rgba(white, 0.5);
    display: flex;
    align-items: center;
}

.Lives {
    margin-left: 2em;
}

.exitIcon {
    $size: 1em;
    width: $size;
    height: $size;
    fill: $iconColor;
}
</style>
