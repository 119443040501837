<template>
  <div class="ShelfList">
    <transition-group
      name="list"
      tag="div"
      class="shelfInner">
      <div
        v-for="item of items"
        :key="item.id"
        class="ShelfItemContainer"
        :style="{ width: `${100 / chunkSize}%` }"
        :data-id="item.id">
        <slot
          name="shelfListItem"
          v-bind="item" />
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            require: true
        },
        chunkSize: {
            type: Number,
            default: 5
        }
    }
};
</script>

<style scoped lang="scss">
.ShelfList {
    height: 100%;
}
.shelfInner {
    display: flex;
    align-items: stretch;
    height: 100%;
}
.ShelfItemContainer {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    $transitionDuration: 0.5s;

    &.list-enter-active,
    &.list-leave-active {
        transition: width $transitionDuration;
        & > /deep/ * {
            opacity: 1;
            transition: opacity $transitionDuration, transform $transitionDuration;
        }
    }
    &.list-enter,
    &.list-leave-to {
        opacity: 0;
        width: 0% !important;
        & > /deep/ * {
            opacity: 0;
            transform: scale(0.5);
        }
    }
}
</style>
