<template>
  <div class="Shelf">
    <Carousel
      ref="shelf"
      class="carousel"
      :style="{ height: carouselHeight, background }"
      :items="carouselItems"
      :index="carouselItemIndex"
      :transition-duration="0.5"
      :side-padding="sidePadding"
      @request-index="setCarouselItemIndex">
      <template
        slot="item"
        slot-scope="props">
        <ShelfList
          v-bind="props"
          :chunk-size="chunkSize"
          mobile-compact="mobileCompact">
          <template
            slot="shelfListItem"
            slot-scope="itemProps">
            <slot
              name="shelfItem"
              v-bind="itemProps" />
          </template>
        </ShelfList>
      </template>
    </Carousel>

    <NavigationWithDots
      :index="carouselItemIndex"
      :length="carouselItems.length"
      @click="setCarouselItemIndex" />
  </div>
</template>

<script>
import { chunkArray } from '@/utils';
import { EventBus } from '@/event-bus';
import Carousel from '@/modules/games-shared/components/Carousel';
import NavigationWithDots from '@/modules/games-shared/components/NavigationWithDots';
import ShelfList from '@/modules/games-shared/components/ShelfList';

export default {
    components: { Carousel, NavigationWithDots, ShelfList },
    props: {
        items: {
            type: Array,
            required: true
        },
        carouselHeight: {
            type: String,
            default: '5em'
        },
        sidePadding: {
            type: String,
            default: '1em'
        },
        mobileCompact: {
            type: Boolean,
            default: true
        },
        usedState: {
            type: Object,
            default: null
        },
        background: {
            type: String
        }
    },
    data() {
        return {
            carouselItemIndex: 0,
            chunkSize: 5
        };
    },
    computed: {
        carouselItems() {
            const availableShelfItems = this.usedState
                ? this.items.filter(item => !this.usedState[item.id])
                : this.items.filter(item => !item.used);

            const arrs = chunkArray(availableShelfItems, this.chunkSize).map(items => {
                return {
                    items
                };
            });
            if (arrs.length === 0) {
                arrs.push({
                    items: []
                });
            }
            return arrs;
        }
    },
    watch: {
        carouselItems(val) {
            if (this.carouselItemIndex >= val.length) {
                this.carouselItemIndex = val.length - 1;
            }
        }
    },
    mounted() {
        EventBus.$on('resize-end', this.updateChunSize);
        if (!this.mobileCompact) {
            this.updateChunSize();
        }
    },
    beforeDestroy() {
        EventBus.$off('resize-end', this.updateChunSize);
    },
    methods: {
        updateChunSize() {
            const breakpoint = window.getComputedStyle(this.$el, ':before').content.replace(/"/g, '');
            this.chunkSize = breakpoint === 'mobile' ? 4 : 5;
        },
        setCarouselItemIndex(index) {
            this.carouselItemIndex = index;
        }
    }
};
</script>

<style lang="scss" scoped>
.Shelf {
}

.carousel {
    border-radius: 0.5em;
    background-color: rgba(white, 0.5);
    overflow: hidden;
}
.NavigationWithDots {
    margin-top: 0.5em;
}

@include media('<mobile') {
    .Shelf {
        &::before {
            display: none;
            visibility: hidden;
            content: 'mobile';
        }
    }
}
</style>
