<template>
  <div
    class="LoadingView"
    @click="goNext()">
    <div
      v-if="level !== null"
      class="levelName">
      {{ $t('level', { level: level.groupIndex + 1, sublevel: level.localIndex + 1 }) }}
    </div>
    <div class="activityLabel">
      {{ $t(`${phase}`) }}
    </div>
    <div
      class="loadingIndicator"
      :class="{ hide: !showLoadingIndicator }">
      <div class="">
        {{ $t('loadingIndicator') }}
      </div>
    </div>
  </div>
</template>

<translations>
  level: 'Level {level}.{sublevel}'
  level_no: 'Nivå {level}.{sublevel}'
  loadingIndicator: 'Loading...'
  loadingIndicator_no: 'Laster...'
  quiz: 'Quiz'
  quiz_no: 'Quiz'
  memorize: 'Memorize'
  memorize_no: 'Memorer'
</translations>

<script>
const MIN_TIME_BEFORE_CONTINUE = 1500;

export default {
    props: {
        phase: {
            type: String,
            required: true,
            validator: function (value) {
                return ['memorize', 'quiz'].indexOf(value) !== -1;
            }
        }
    },
    inject: ['transition', 'module'],
    data() {
        return {
            showLoadingIndicator: false
        };
    },
    computed: {
        level() {
            if (!this.module.state.levelInfo) {
                return null;
            }
            return this.$store.state[this.module.state.moduleName].levels[this.module.state.levelInfo.levelIndex];
        },
        isLoading() {
            return this.module.state.appIsLoading || this.module.state[this.phase].loading || false;
        }
    },
    watch: {
        isLoading() {
            const timeSinceMounted = Date.now() - this.mountedTimestamp;
            if (timeSinceMounted < MIN_TIME_BEFORE_CONTINUE) {
                this.timeoutId = setTimeout(() => {
                    this.goNext();
                }, MIN_TIME_BEFORE_CONTINUE - timeSinceMounted);
            } else {
                this.goNext();
            }
        }
    },
    mounted() {
        if (this.isLoading) {
            this.showLoadingIndicator = true;
            this.mountedTimestamp = Date.now();
        } else {
            this.timeoutId = setTimeout(() => {
                this.goNext();
            }, MIN_TIME_BEFORE_CONTINUE);
        }
    },
    beforeDestroy() {
        clearTimeout(this.timeoutId);
    },
    methods: {
        goNext() {
            if (this.isLoading) {
                return;
            }
            this.transition('COUNTDOWN');
        }
    }
};
</script>

<style scoped lang="scss">
.LoadingView {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    $transitionDuration: 0.5s;
    &.banner_to_none-enter-active > * {
        transition: opacity $transitionDuration linear 0.5s;
    }
    &.banner_to_none-enter > * {
        opacity: 0;
    }

    $transitionDuration: 0.5s;
    &.none_to_none-leave-active > * {
        transition: opacity $transitionDuration;
    }
    &.none_to_none-leave > * {
        opacity: 0;
    }
}
.levelName {
    margin-bottom: 0.5em;
    color: rgba(white, 0.5);
}
.activityLabel {
    font-size: 200%;
    text-transform: capitalize;
}
.loadingIndicator {
    position: absolute;
    margin-top: 1em;
    bottom: 2em;
    color: rgba(white, 0.5);
    left: 0;
    right: 0;
    text-align: center;

    &.hide {
        opacity: 0;
    }
}
</style>
