<template>
  <div class="NavigationWithDots">
    <div
      v-for="i of length"
      :key="`dot-${i}`"
      class="Dot --global-clickable"
      :class="{ isActive: i === index + 1 }"
      @click="$emit('click', i - 1)" />
  </div>
</template>

<script>
export default {
    props: {
        length: {
            type: Number,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    }
};
</script>

<style scoped lang="scss">
.NavigationWithDots {
    display: flex;
    justify-content: center;
}
.Dot {
    $size: 0.5em;
    position: relative;
    width: $size;
    height: $size;
    background-color: rgba(white, 0.2);
    border-radius: 50%;
    margin: 0.3em;

    &::after {
        $extra: -0.3em;
        content: '';
        display: block;
        position: absolute;
        top: $extra;
        right: $extra;
        bottom: $extra;
        left: $extra;
    }

    &.isActive {
        background-color: rgba(white, 1);
    }

    &:first-child:last-child {
        opacity: 0;
    }
}
</style>
