<template>
  <div class="ProgressBar">
    <div
      class="indicator"
      :style="style" />
  </div>
</template>

<script>
export default {
    props: {
        progress: {
            type: Number,
            required: true
        }
    },
    computed: {
        style() {
            return {
                // width: `${this.progress * 100}%`,
                transform: `scale(${this.progress}, 1)`,
                backgroundColor: 'white'
            };
        }
    }
};
</script>

<style scoped lang="scss">
.ProgressBar {
}
.indicator {
    // transition: width 1s;
    transition: transform 0.5s;
    transform-origin: top left;
    height: 0.2em;
    width: 100%;
}
</style>
