import shuffle from 'lodash-es/shuffle';

import store from '@/store';
import { pickUniqueRandomItemsWithMemory, getUniqueRandomNumbers } from '@/modules/memogenius-game/utils';

import shopitems from './data/shopitems';

const images = require.context('./images/items', true, /\.(png|jpg)$/);

function imageUrl(localUrl) {
    try {
        return images(`./${localUrl}`);
    } catch (err) {
        return null;
    }
}

class QuestionService {
    getItems(numberOfItems, { includeQuantity, includePrice }) {
        const language = store.getters['moduleApp/locale'];
        const state = store.state.moduleGameShopping.questionServiceState;
        const usedMap = JSON.parse(JSON.stringify(state.usedMap || {}));

        return pickUniqueRandomItemsWithMemory(shopitems[language], numberOfItems, {
            used: usedMap.normal,
            minimumAvailable: numberOfItems + 30,
            persist(used) {
                usedMap.normal = used;
                store.dispatch('moduleGameShopping/updateQuestionServiceState', { usedMap });
            }
        }).map((itemRaw, i) => {
            const item = {
                number: i + 1,
                name: itemRaw.name,
                image: imageUrl(`shopping-${itemRaw.id}.png`),
                unit: itemRaw.unit
            };

            if (includeQuantity) {
                item.quantity = parseInt(Math.random() * 9) + 1;
            }
            if (includePrice) {
                item.price = parseInt(Math.random() * 9) + 1;
            }
            return item;
        });
    }

    getQuantities(numberOfOptions, include = []) {
        const seen = {};
        include.forEach(x => {
            seen[x] = true;
        });
        const options = getUniqueRandomNumbers(numberOfOptions - include.length, 1, 10, seen);
        return shuffle([...options, ...include]);
    }

    getPrices(numberOfOptions, include = []) {
        const seen = {};
        include.forEach(x => {
            seen[x] = true;
        });
        const options = getUniqueRandomNumbers(numberOfOptions - include.length, 1, 10, seen);
        return shuffle([...options, ...include]);
    }
}

const questionService = new QuestionService();
export default questionService;
